import React, { useState } from 'react'
import HeaderMain from "../HeaderMain/HeaderMain";
import FooterNew from "../FooterNew/FooterNew";
import { Container, Row, Col, Button } from 'react-bootstrap'
import BoxingListBody from './BoxingListBody';
import './BoxingList.css';
import BoxingListTop from './BoxingListTop';
import BoxingListRightSide from './BoxingListRightSide';
import BoxingListLeftSide from './BoxingListLeftSide';


const BoxingList = () => {

  const [data, setdata] = useState()

  const datas = (dt) => {
    setdata(dt)
  }
  return (
    <div className='Boxinglist-page'>

      <HeaderMain />


      <Container fluid>

        <Row>

          <Col xs={12} sm={12} md={12} lg={2} xl={2}>
            <BoxingListLeftSide />
          </Col>

          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <BoxingListTop datas={datas} />
            <BoxingListBody status={data} />
            <FooterNew />
          </Col>


          <Col xs={12} sm={12} md={12} lg={2} xl={2}>
            <BoxingListRightSide />
          </Col>


        </Row>


      </Container>



    </div>
  )
}

export default BoxingList
