import React, { useState, useRef, useEffect } from 'react'
import './EmailVerify.css'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Axios from '../../Axios'

const EmailVerify = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const dataa = searchParams.get('token')
    const firstrun = async () => {
        try {
            if (dataa !== null) {
                const { data } = await Axios.post(`/auth/verify`, {}, {
                    headers: {
                        "Authorization": dataa
                    }
                })
                console.log(data, "res")
                if (data?.success === true) {
                    console.log("success")
                    navigate('/', { state: { status: "Verified", page: "email" } })
                } else {
                    console.log("false")
                    navigate('/', { state: { status: "Already Verified", page: "email" } })
                }
            }
        } catch ({ response }) {
            if (response?.data === "Unauthorized") {
                console.log(response?.data, "error")
                navigate('/', { state: { status: "error", page: "email" } })
            }
        }
    }
    useEffect(() => {
        firstrun()
    }, [])

    return (
        <>

        </>
    )
}
export default EmailVerify