import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import settingicon from '../../images/setting-icon.svg'
import boxinggloves from '../../images/boxing_gloves.svg'

const BoxingListTop = ({ datas }) => {


    const [data, setdata] = useState(false)

    useEffect(() => {
        datas(data)
    }, [data])
    return (
        <div className='top-section'>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <div className='header-text'>
                            <h4>UPCOMING MATCHES Boxing</h4>
                        </div>
                    </Col>

                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className='right-side'>
                        <div className='header-text icon'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={settingicon} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">setting-icon</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Form>
                                <span>Asian view</span>
                                <Form.Check type="switch" id="custom-switch" />
                            </Form>

                        </div>
                    </Col>

                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='center-text'>
                            <img src={boxinggloves} />
                            <h6>World. Fights</h6>
                        </div>
                    </Col>

                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='date-time'>
                            <span>11 Jun / 07:30</span>
                            <span className='disrow' >
                                <Button onClick={() => { setdata(!data); }}>History</Button>
                            </span>
                        </div>

                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default BoxingListTop
