import React, { useState, useRef, useEffect } from 'react'
import './optresend.css'
import HeaderMain from "../HeaderMain/HeaderMain";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';


import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify';
import { NavItem } from 'react-bootstrap';
import Const from '../../Const';

const Otpresend = ({ setIsDrawerOpen, isDrawerOpen }) => {

    const navigate = useNavigate()
    const location = useLocation();
    const email = useRef(null)
    const password = useRef(null)

    const [emailerr, setemailerr] = useState(null)
    const [passerr, setpasserr] = useState(null)

    const [showPassword, setshowPassword] = useState(false)
    const handleClickShowPassword = () => setshowPassword((show) => !show);

    const [verifystatus, setverifystatus] = useState("empty")


    const onLogin = async () => {
        try {
            const { data } = await Axios.post(`/auth/resendemail`, {
                email: location?.state?.email
            })
            if (data?.success === true) {
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            if (data.success === true) {
                window.localStorage.setItem('Mellifluous', data.result.token)
                toast.success(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                setTimeout(() => {
                    navigate('/')
                }, 2000)
            }

        } catch ({ response }) {
            if (response?.data?.message === "Already Verified") {
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            // if (response?.data?.message === 'Please Verify Email') {
            //     setemailerr(response?.data?.message)
            // } else if (response?.data?.message === "Invalid Email") {
            //     setemailerr(response?.data?.message)
            // } else if (response?.data?.message === 'Wrong Password') {
            //     setpasserr(response?.data?.message)
            // }
        }
    }

    return (
        <div className="login-page-main1">
            <HeaderMain setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} />
            <ToastContainer />
            <div className='login-section1'>
                <Container>
                    <div className='login-bg1'>
                        <div className='login-inside1'>
                            <div className='success'>
                                <span className="login-heading-style1">SUCCESS</span>
                                <i class="fa fa-check-circle tick-green" style={{ color: '#126e51' }} aria-hidden="true"></i>
                            </div>

                            <div>
                                <p>Thank you for registering on mmadness! Please click on the verification link sent to your Email ID to
                                    activate your account. If you cannot find the email, please check your spam and trash folders to ensure that the email hasn’t been
                                    marked as spam or accidentally deleted.</p>
                                <Button type="submit" onClick={onLogin}>Resend Email</Button>
                                <Button type="submit" onClick={() => { navigate(`${Const.weblink}/`) }} >Next</Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Otpresend
