import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Component/Home/Home';
import Notfound from './Component/Notfound';
import Deposit from './Component/Deposit/Deposit';
import SportsRule from './Component/SportsRule/SportsRule';
import Terms from './Component/Terms/Terms';
import Account from './Component/Account/Account';
import Protection from './Component/Protection/Protection';
import Technical from './Component/Technical/Technical';
import Faq from './Component/Faq/Faq';
import Privacy from './Component/Privacy/Privacy';
import BoxingList from './Component/BoxingList/BoxingList';
import BoxingMatch from './Component/BoxingMatch/BoxingMatch';
import UFCMatch from './Component/UFCMatch/UFCMatch';
import Const from './Const';
import Login from './Component/Login/Login';
import Register from './Component/Register/Register';
import Otpresend from './Component/OtpResend/Otpresend'
import TwoFactorAuth from './Component/TwoFactorAuth/TwoFactorAuth';
import EmailVerify from './Component/EmailVerify/EmailVerify';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={`${Const.weblink}`} element={<Home />} />
          <Route path={`${Const.weblink}/deposit`} element={<Deposit />} />
          <Route path={`${Const.weblink}/sports-rule`} element={<SportsRule />} />
          <Route path={`${Const.weblink}/myaccount`} element={<Account />} />
          <Route path={`${Const.weblink}/terms-and-conditions`} element={<Terms />} />
          <Route path={`${Const.weblink}/technical`} element={<Technical />} />
          <Route path={`${Const.weblink}/faq`} element={<Faq />} />
          <Route path={`${Const.weblink}/player-protection`} element={<Protection />} />
          <Route path={`${Const.weblink}/privacy-policy`} element={<Privacy />} />
          <Route path={`${Const.weblink}/boxing-list`} element={<BoxingList />} />
          <Route path={`${Const.weblink}/boxing-match`} element={<BoxingMatch />} />
          <Route path={`${Const.weblink}/ufc-match`} element={<UFCMatch />} />
          <Route path={`${Const.weblink}/login`} element={<Login />} />
          <Route path={`${Const.weblink}/register`} element={<Register />} />
          <Route path={`${Const.weblink}/emailotp`} element={<Otpresend />} />
          <Route path={`${Const.weblink}/two-factor-auth`} element={<TwoFactorAuth />} />
          <Route path={`${Const.weblink}/emailverify`} element={<EmailVerify />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
