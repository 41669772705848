import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import top from '../../images/top.svg'
import Parlays from '../../images/Parlays.svg'
import events from '../../images/events.svg'
import Accordion from 'react-bootstrap/Accordion';
import boxinggloves from '../../images/boxing_gloves.svg'
import Form from 'react-bootstrap/Form';

const BoxingListLeftSide = () => {
  return (
    <div className='outer-left-side'>
      <Container>
        <Row>
            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                <div className='events'>
                    <div className='events'>
                        <img src={top} alt="Trending"/>
                        <p>Top</p>
                    </div>
                    <div className='events'>
                        <img src={Parlays} alt="Trending"/>
                        <p>Top Parlays</p>
                    </div>
                    <div className='events'>
                        <img src={events} alt="Trending"/>
                        <p>National events</p>
                    </div>
                </div>
            </Col>

            <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                <div className='tournament'>
                    <div className='upcoming'>
                    <p>1H</p>
                    <p>3H</p>
                    <p>12H</p>
                    <p className='active'>All upcoming</p>
                    </div>
                    
                <p>All popular tournaments</p>

                <h5>Boxing</h5>


                <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
                <div className='center-text'>
                    <img src={boxinggloves}/>
                    <h6>World. Fights</h6>
                </div>
        </Accordion.Header>
        <Accordion.Body>
        <Form>
      {['checkbox'].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`default ${type}`}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`default ${type}`}
          />
          <Form.Check // prettier-ignore
            type={type}
            id={`default-${type}`}
            label={`default ${type}`}
          />
        </div>
      ))}
    </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

                </div>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BoxingListLeftSide
