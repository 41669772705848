import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import './FooterNew.css';
import { Container, Row, Col, Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import settingicon from '../../images/setting-icon.svg'
import boxinggloves from '../../images/boxing_gloves.svg'
import Josh from '../../images/Josh.svg'
import Lopez from '../../images/Lopez.svg'
import { Link, useNavigate } from 'react-router-dom'
import  upi from '../../images/upi.svg'
import  hdfc from '../../images/hdfc.svg'
import  axis from '../../images/axis.svg'
import  sbi from '../../images/sbi.svg'
import  icici from '../../images/icici.svg'
import  cash from '../../images/cash.svg'
import  visa from '../../images/visa.svg'
import  medium from '../../images/medium.svg'
import  rupay from '../../images/rupay.svg'
import  paytm from '../../images/paytm.svg'
import  phonepay from '../../images/phonepay.svg'
import  crypto from '../../images/crypto.svg'
import  yesbank from '../../images/yesbank.svg'
import  kotak from '../../images/kotak.svg'
import  headphone from '../../images/headphone.svg'

const FooterNew = () => {
  return (
    <div className='footer_new'>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Popular events and tournaments</Accordion.Header>
        <Accordion.Body>
            <Row>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div className='score board'>
                            <div className='team-name'>
                                <div className='single-team'>
                                    <img src={Josh} alt="Josh_Taylor"/>
                                    <h6>Josh Taylor</h6>
                                </div>

                                <div className='center-at'>
                                    <h2>VS</h2>
                                    <h4>09:30</h4>
                                </div>

                                <div className='single-team'>
                                    <img src={Lopez} alt="Lopez"/>
                                    <h6>Teofimo Lopez</h6>
                                </div>

                            </div>

                            <div className='bet-btn'>
                                <Link to='' className='Bet' variant="warning">Bet Now</Link>
                            </div>

                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div className='score board'>
                            <div className='team-name'>
                                <div className='single-team'>
                                    <img src={Josh} alt="Josh_Taylor"/>
                                    <h6>Josh Taylor</h6>
                                </div>

                                <div className='center-at'>
                                    <h2>VS</h2>
                                    <h4>09:30</h4>
                                </div>

                                <div className='single-team'>
                                    <img src={Lopez} alt="Lopez"/>
                                    <h6>Teofimo Lopez</h6>
                                </div>

                            </div>

                            <div className='bet-btn'>
                                <Link to='' className='Bet' variant="warning">Bet Now</Link>
                            </div>

                        </div>
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div className='score board'>
                            <div className='team-name'>
                                <div className='single-team'>
                                    <img src={Josh} alt="Josh_Taylor"/>
                                    <h6>Josh Taylor</h6>
                                </div>

                                <div className='center-at'>
                                    <h2>VS</h2>
                                    <h4>09:30</h4>
                                </div>

                                <div className='single-team'>
                                    <img src={Lopez} alt="Lopez"/>
                                    <h6>Teofimo Lopez</h6>
                                </div>

                            </div>

                            <div className='bet-btn'>
                                <Link to='' className='Bet' variant="warning">Bet Now</Link>
                            </div>

                        </div>
                    </Col>
            </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

            <div className='footer-link'>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <ul>
                                <li>Help</li>
                                <li>Deposit</li>
                                <li>Withdrawal</li>
                                <li>Support</li>
                            </ul>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <ul>
                                <li>Parimatch</li>
                                <li>Terms & Conditions</li>
                                <li>Privacy Policy</li>
                                <li>Public Offer</li>
                                <li>AML/KYC Policy</li>
                                <li>For partners</li>
                            </ul>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <ul>
                                <li>Info</li>
                                <li>Responsible gambling</li>
                                <li>Self-exclusion</li>
                                <li>Dispute resolution</li>
                                <li>Privacy & Management of Personal Data</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='available-pay'>
                <h5>Available payment methods</h5>
            
            <div className='payment-method'>
                <div className='bank-name'>
                    <img src={upi} alt="upi"/>
                </div>
                <div className='bank-name'>
                    <img src={hdfc} alt="hdfc"/>
                </div>
                <div className='bank-name'>
                    <img src={axis} alt="axis"/>
                </div>
                <div className='bank-name'>
                    <img src={sbi} alt="sbi"/>
                </div>
                <div className='bank-name'>
                    <img src={icici} alt="icici"/>
                </div>
                <div className='bank-name'>
                    <img src={cash} alt="cash"/>
                </div>
                <div className='bank-name'>
                    <img src={visa} alt="visa"/>
                </div>
                <div className='bank-name'>
                    <img src={medium} alt="medium"/>
                </div>
                <div className='bank-name'>
                    <img src={rupay} alt="rupay"/>
                </div>
                <div className='bank-name'>
                    <img src={paytm} alt="paytm"/>
                </div>
                <div className='bank-name'>
                    <img src={phonepay} alt="phonepay"/>
                </div>
            </div>

            <div className='payment-method'>
                <div className='bank-name'>
                    <img src={crypto} alt="crypto"/>
                </div>
                <div className='bank-name'>
                    <img src={yesbank} alt="yesbank"/>
                </div>
                <div className='bank-name'>
                    <img src={kotak} alt="kotak"/>
                </div>
            </div>

            </div>

            <div className='cookies'>
                <Container>
                    <Row>
                        <Col xl={7} lg={7} md={12} sm={12} xs={12} className='ensure'>
                            <div className='copyright'>
                                <h6>Uses cookies to ensure the best user experience. By remaining on the website, you consent to the use of  <br/> your cookie files on <span>Find out more</span> </h6>
                                <h5>Copyright © 2007 - 2023 «MMADNESS» </h5>
                            </div>
                        </Col>

                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <div className='customer-care'>
                                <div className='headphone'>
                                    <img src={headphone} alt="headphone"/>
                                </div>
                                <div className='number'>
                                <h3>Support</h3>
                                <h2>000 800 919-02-66</h2>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


    </div>
  )
}

export default FooterNew
