import React from 'react'
import betslipicon from '../../images/betslip-icon.png'
import deletebeticon from '../../images/delete-bet-icon.png'
import deletebetitembtn from '../../images/delete-bet-item-btn.png'
import { Container, Row, Col, Button } from 'react-bootstrap'

const RightSidebarBet = () => {
  return (
    <div className='Right-Sidebar-Bet'>
       <div className='betslip-icon'><img src={betslipicon}/><h5>Betslip</h5></div>
       <div className='betslip-delete-head'>
         <div><img src={deletebeticon} alt="delete-bet-icon"/></div>
         <span className='Single-Bet'>Single Bet</span>
         <span className='Parlay'>Parlay</span>
       </div>
       <div>
          <div className='bet-items-list'>
             <div className='deletebetitembtn'><img src={deletebetitembtn} alt="delete-bet-item-btn"/></div>
             <div className='bet-items-select-mid'>
                <span><label>Match result</label> — Teofimo Lopez</span>
                <div>Josh Taylor - Teofimo Lopez</div>
             </div>
             <div className='bet-points-value'>2.2</div>
          </div>
          <div className='bet-items-list'>
             <div className='deletebetitembtn'><img src={deletebetitembtn} alt="delete-bet-item-btn"/></div>
             <div className='bet-items-select-mid'>
                <span><label>Match result</label> — Teofimo Lopez</span>
                <div>Josh Taylor - Teofimo Lopez</div>
             </div>
             <div className='bet-points-value'>2.2</div>
          </div>
          <div className='bet-items-list'>
             <div className='deletebetitembtn'><img src={deletebetitembtn} alt="delete-bet-item-btn"/></div>
             <div className='bet-items-select-mid'>
                <span><label>Match result</label> — Teofimo Lopez</span>
                <div>Josh Taylor - Teofimo Lopez</div>
             </div>
             <div className='bet-points-value'>2.2</div>
          </div>
          <div className='total-odds'><span>Total Odds:</span><span>6.6</span></div>
          <div className='bet-amount-part total-odds'>
            <span>Bet Amount:</span>
            <input type="text" value="500" />
            <label>INR</label>
          </div>
          <div className='bet-amount-value-btns'>
             <Button>100</Button>
             <Button>200</Button>
             <Button>1000</Button>
          </div>
          <div className='parlay-active'>
          <Button>Parlay boost bonus <span>Activated</span></Button>
          </div>
       </div>
       <div className='login-right-side-outer'><Button className='login-right-side'>Login</Button></div>
    </div>
  )
}

export default RightSidebarBet
