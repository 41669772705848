import React, {useState} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import bannerimage from '../../images/banner-image.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const BannerMain = () => {
    
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  
    return (
      <div className='carousel-part-top-home'>
        <Container>
            <Row>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerimage}
            alt="First slide"
          />
          <Carousel.Caption>
          <h1>Online Boxing Betting 2023</h1>
          <p>Lorem ipsum dolor sit amet consectetur. Adipiscing massa pellentesque interdum netus quam sodales nec urna. Mi convallis enim proin urna. Urna vel quis et velit. In turpis praesent adipiscing morbi a viverra eget lacus. Malesuada mauris at in augue. Ac.</p>
        </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerimage}
            alt="Second slide"
          />
          <Carousel.Caption>
          <h1>Online Boxing Betting 2024</h1>
          <p>Lorem ipsum dolor sit amet consectetur. Adipiscing massa pellentesque interdum netus quam sodales nec urna. Mi convallis enim proin urna. Urna vel quis et velit. In turpis praesent adipiscing morbi a viverra eget lacus. Malesuada mauris at in augue. Ac.</p>
        </Carousel.Caption>
        </Carousel.Item>
        
      </Carousel>
      </Row>
      </Container>
      </div>
    );
}

export default BannerMain
