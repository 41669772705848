import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logomainpage from '../../images/head-logo.png'
import bdt from '../../images/bdt.png'
import Button from 'react-bootstrap/Button';
import './HeaderMain.css'
import { ImFacebook2 } from 'react-icons/im'
import { RiWhatsappFill } from 'react-icons/ri'
import { RiMailOpenFill } from 'react-icons/ri'
import { AiFillHome } from 'react-icons/ai'
import { ImMobile } from 'react-icons/im'
import { Link, useNavigate } from 'react-router-dom'
import Login from '../Login/Login';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faUser, faDownload } from '@fortawesome/free-solid-svg-icons'
import { QRCodeSVG } from "qrcode.react";
import search from '../../images/search.png'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Axios from '../../Axiostoken';
import { ToastContainer, toast } from 'react-toastify';
import Const from '../../Const';

const HeaderMain = () => {

  const navigate = useNavigate()
  const verifySecret = useRef(null)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [twoFactorEnable, setTwoFactorEnable] = useState("disable")
  const [secret, setSecret] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [emailerr, setemailerr] = useState(null)
  const [ena, setEna] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)

  const [users, setusers] = useState(null)

  var tokenCheck = window.localStorage.getItem('Mellifluous')

  const enable = async () => {
    setShow(false)
    if (tokenCheck !== null) {
      const data = await Axios.get(`/auth/check2fa`, {
        headers: {
          "Authorization": tokenCheck
        }
      })
      setusers(data?.data?.result)
      console.log(data?.data?.result, "resuktsd")
      if (data?.data?.result?.status === true) {
        setTwoFactorEnable("enable")
      } else {
        setTwoFactorEnable("disable")
        setEna(false)
      }
    } else {
      setTwoFactorEnable("enable")
    }
  }


  useEffect(() => {
    enable()
  }, [tokenCheck])

  const handleProfileOpen = () => {
    setProfileOpen(!profileOpen)
  }

  const logout = () => {
    window.localStorage.removeItem('Mellifluous')
    navigate(`${Const.weblink}/`)
  }

  const handleDisable = async () => {
    const { data } = await Axios.post(`/auth/disable2fa`, {}, {
      headers: {
        "Authorization": tokenCheck
      }
    })
    if (data?.success === true) {
      setEna(false)
      setTwoFactorEnable("disable")
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

  }
  const handleEnable = async () => {
    setEna(true)
    const { data } = await Axios.post(`/auth/generate2fa`, {}, {
      headers: {
        "Authorization": tokenCheck
      }
    })
    setSecret(data.result.secret)
    setQrCode(data.result.otpauth_url)
  }

  const verify2FA = async () => {
    try {
      const { data } = await Axios.post(`/auth/verify2fa`, {
        secret: verifySecret.current.value
      }, {
        headers: {
          "Authorization": tokenCheck
        }
      })
      if (data?.message === '2FA Verified Successfully') {
        setEna(false)
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTwoFactorEnable("enable")
      verifySecret.current.value = ""

    } catch ({ response }) {
      if (response?.data?.success === false) {
        setemailerr("Invalid-Otp")
        toast.error("Invalid-Otp", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        verifySecret.current.value = ""
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='header-top'>
        <Container>
          <Row>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='header-top-left'>
                {/* <div className='gmt-time'><span>(GMT+.5:00) 12:32:13</span></div>
                <div className='origin-country'><img src={bdt} /><span>BDT</span><span>Bangla</span></div> */}
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='header-top-right'>
                <div><ImFacebook2 /> Facebook</div>
                <div><RiWhatsappFill /> Whatsapp</div>
                <div><RiMailOpenFill /> Email</div>
              </div>
            </Col>

          </Row>
        </Container>
      </div>

      <div className='HeaderMain'>
        <Container>
          <Row>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='logomainpage'><Link to={`${Const.weblink}/`}><img src={logomainpage} alt="logo-main-page" /></Link></div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="last-menu">
                {/* <div className='search'><img src={search} alt="search" /></div> */}
                {tokenCheck ? <></> :
                  <Button onClick={() => { navigate(`${Const.weblink}/register`) }} variant="primary" className='sign-up-btn'>sign up</Button>
                }
                {tokenCheck ?
                  <div className='user-login-part'>
                    <Button style={{ backgroundColor: "#770d0e" }} onClick={() => { navigate(`${Const.weblink}/boxing-list`) }} >Matches</Button>
                    <div className='user-after-login' onClick={() => handleProfileOpen()}><FontAwesomeIcon icon={faUser} /></div>
                    {profileOpen === true &&
                      <div className='user-profile-dropdwn'>
                        <div class='top-user-block'>
                          {users?.user_name ?
                            <>
                              <span>{users?.user_name}<span> ${users?.amount}</span></span>
                            </> :
                            <>
                              <span>User<span>Rs.0.00</span></span>
                            </>}

                          <Button className='user-profile-deposit' onClick={() => { navigate(`${Const.weblink}/deposit`) }}  ><FontAwesomeIcon icon={faDownload} /> Deposit</Button>
                        </div>
                        {/* <div class='mid-user-block'>
                          <div><span>Withdrawable</span><span>Rs 0.00</span></div>
                          <div><span>Bet Credits</span><span>Rs 0.00</span></div>
                        </div> */}
                        <div className='bottom-user-block'>

                          <Tabs
                            defaultActiveKey="account"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            style={{ justifyContent: 'space-evenly' }}
                          >
                            <Tab eventKey="account" title="Account">
                              <div className='user-account-detail-block'>
                                <h2>Account1</h2>
                                <button className='logout' onClick={logout}>logout</button>
                              </div>
                            </Tab>
                            <Tab eventKey="twofac" title="2FA">

                              <div className='two-fa-outer'>
                                <div className='two-fa-status'>
                                  <h3>2FA Status</h3>
                                  {twoFactorEnable === "disable" ?
                                    <span className='disabled-block'><span className='disabled-dot'></span>Disabled</span>

                                    : <span className='enabled-block'><span className='enabled-dot'></span>Enabled</span>
                                  }
                                </div>
                                {twoFactorEnable === "disable" ?
                                  <div className='enable-button'><Button onClick={() => handleEnable()} variant="success">Enable</Button></div>
                                  : <div className='disable-button'><Button onClick={() => handleDisable()} variant="danger">Disable</Button></div>
                                }
                              </div>
                              {ena === true ?
                                <div>
                                  <div className='Security-Code'>
                                    <span>Security Code:</span>
                                    {secret}
                                  </div>
                                  <div className='Security-Code qr-code'>
                                    <span>QR Code:</span>
                                    <div className='qr-code-code'>
                                      <QRCodeSVG value={qrCode} />
                                    </div>
                                  </div>
                                </div> :
                                <div>
                                </div>
                              }
                              <div className='verif-2fa-digit'>
                                <div className='verif-2fa-digit-inner'>
                                  <input ref={verifySecret} type="text" onChange={() => { setemailerr(null) }} />
                                  <Button onClick={() => { verify2FA(); }} style={{ backgroundColor: "#198754", background: "", color: "black" }}  >Verify</Button>
                                </div>
                                {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>}
                              </div>
                            </Tab>
                            <Tab eventKey="wallet" title="Wallet">

                            </Tab>
                          </Tabs>

                        </div>
                      </div>
                    }
                  </div>
                  : <Button onClick={handleShow} variant="primary" className="login-btn">Log in</Button>}
              </div>
              {/* <div className='login-sign-up-part'>
            <Button onClick={handleShow} variant="primary" className="login-btn">Log in</Button>
            <Button variant="primary" className='sign-up-btn'>sign up</Button>
            </div> */}
            </Col>

          </Row >
        </Container >
      </div >

      <div className='header-last menu-part'>

        <Container>
          <Row>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='home-mobile'><Link><AiFillHome /></Link><span className='divider'>|</span><Link><ImMobile /></Link></div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className='login-sign-up-part'>
                <nav>
                  <ul className='menu-part-list'>
                    <li><Link>Promotional offer</Link></li>
                    <li><Link>VIP</Link></li>
                  </ul>
                </nav>
              </div>
            </Col>

          </Row>
        </Container>

      </div>
      <Modal show={show} onHide={handleClose} className='login-in-popup-box'>
        <Modal.Header closeButton></Modal.Header>
        <div className='login-pop-up'>
          <Login />
        </div>
      </Modal>
    </>
  )
}

export default HeaderMain
