import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { AiFillInfoCircle, AiOutlineStar } from 'react-icons/ai'
import { FiBell } from 'react-icons/fi'
import player1 from '../../images/player-1.png'
import player2 from '../../images/player-2.png'
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import settingicon from '../../images/setting-icon.svg'
import boxinggloves from '../../images/boxing_gloves.svg'
import { useLocation } from 'react-router'
import firstimg from '../../image/jarde.png'
import secondimg from '../../image/VETTORI_MARVIN_03-18.png'

const BoxingMatchBody = () => {
    const location = useLocation()
    console.log(location.state.data, "datas")
    const [data, setdata] = useState()

    useEffect(() => {
        setdata(location?.state?.data)
    }, [location?.state?.data])
    return (
        <div className='boxing-match-body-part'>

            <div className='live-match-most-top top-section'>

                <Container fluid>
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div className='header-text up-coming-matches-top-head'>
                                <p>Boxing Betting Odds / World Fights</p>
                                <h4>UPCOMING MATCHES Boxing</h4>
                            </div>
                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className='right-side'>
                            <div className='header-text icon'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <img src={settingicon} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">setting-icon</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Form>
                                    <span>Asian view</span>
                                    <Form.Check type="switch" id="custom-switch" />
                                </Form>

                            </div>
                        </Col>

                        {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='center-text'>
                            <img src={boxinggloves}/>
                            <h6>World. Fights</h6>
                        </div>
                    </Col>

                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='date-time'>
                            <span>11 Jun / 07:30</span>
                        </div>
                    </Col> */}
                    </Row>
                </Container>

            </div>

            <div className='live-match-top'>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='match-journal'>
                                <span className='game-name-field'>Boxing</span>
                                <span className='game-cat-name-field'>World. Fights</span>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='betting-ingo-block'>
                                <Button variant="secondary" className='Betting-info'>Betting info <AiFillInfoCircle /></Button>
                                <AiOutlineStar />
                                <FiBell />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='live-match-running-between-verses'>
                <Container>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='oponent left'>
                                <div className='player-image'><img src={data?.image1} alt="player-1" width='63px' /></div>
                                <div className='opponent-image-name'>{data?.away}</div>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='match-timing-starts'>
                                <label>{data?.month}</label>
                                <span>{data?.time}</span>
                                <span>VS</span>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='inner-right-align'>
                                <div className='oponent right-align'>
                                    <div className='player-image'><img src={data?.image2} alt="player-2" width='63px' /></div>
                                    <div className='opponent-image-name'>{data?.home}</div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>


            <div className='live-match-betting-points'>
                <Container fluid>
                    <Row>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='betting-points-left'>
                                <div className='round-level'>RO-1</div>
                                <div className='betting-point-table'>

                                    <div className='betting-point-table-sp'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>100pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>SP</div><div className='short-cut-points points'>25pts</div></div>
                                    </div>

                                    <div className='betting-point-table-kout'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>25pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name small-font'>KOW</div><div className='short-cut-points points'>500pts</div></div>
                                    </div>

                                </div>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='betting-points-mid'>
                                <div className='round-level'>RO-2</div>
                                <div className='betting-point-table'>

                                    <div className='betting-point-table-sp'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>100pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>SP</div><div className='short-cut-points points'>25pts</div></div>
                                    </div>

                                    <div className='betting-point-table-kout'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>25pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name small-font'>KOW</div><div className='short-cut-points points'>500pts</div></div>
                                    </div>

                                </div>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='betting-points-right'>
                                <div className='round-level'>RO-3</div>
                                <div className='betting-point-table'>

                                    <div className='betting-point-table-sp'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>100pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>SP</div><div className='short-cut-points points'>25pts</div></div>
                                    </div>

                                    <div className='betting-point-table-kout'>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>HP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>BP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>TP</div><div className='short-cut-points'></div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name'>RW</div><div className='short-cut-points points'>25pts</div></div>
                                        <div className='points-short-cut-outer'><div className='short-cut-name small-font'>KOW</div><div className='short-cut-points points'>500pts</div></div>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default BoxingMatchBody
