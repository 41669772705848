import React from 'react'
import { Container } from 'react-bootstrap'
import './FooterHelp.css'
import { Link } from 'react-router-dom'
import footlogo from '../../img/ncgp-img.png'
import footimg from '../../img/c-img.png'
import footimage from '../../img/21-img.png'
const FooterHelp = () => {
  return (
    <div className='whole-footer'>
      <Container>

        <div className='fc-footer'>

          <div class="fc__footer__item">
            <div class="fc-footer-links">
              <Link to="">Contact Us</Link>
              <Link to="">Responsible Gaming</Link>
              <Link to="/mmadness/privacy-policy">Privacy Policy</Link>
              <Link to="">About Us</Link>
              <Link to="/mmadness/sports-rule">Sports Rules</Link>
              <Link to="/mmadness/terms-and-conditions">Terms and Conditions</Link>

            </div>
            <div class="fc-footer-links">
              <Link to="">Complaints Procedure</Link>
              <Link to="">House Rules</Link>
              <Link to="">Player Protection</Link>
            </div>
          </div>
          <div>
            <img src={footlogo} alt="Footer-image" className='footer-img' />
            <img src={footimg} alt="Footer-image" className='footer-img' />
            <img src={footimage} alt="Footer-image" className='footer-img' />
          </div>

          <div>
            <p className='footer-para'>Gambling can be addictive, please play responsibly. For information on support measures, please visit our Responsible Gambling Help page. © 2001-2023 Mmadness. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FooterHelp
