import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './Login.css'
import login1 from '../../images/login-1.png'
import login2 from '../../images/login-2.png'
import login3 from '../../images/login-3.png'

import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify';
import Const from '../../Const'


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {

  const navigate = useNavigate()
  const location = useLocation;
  const email = useRef(null)
  const password = useRef(null)

  const [emailerr, setemailerr] = useState(null)
  const [passerr, setpasserr] = useState(null)

  const [showPassword, setshowPassword] = useState(false)
  const handleClickShowPassword = () => setshowPassword((show) => !show);

  const [verifystatus, setverifystatus] = useState("empty")

  const [visibile, setvisible] = useState(false)

  const checkVerify = () => {
    const data = location?.state?.status
    if (location?.state?.page === "email") {
      setverifystatus(data)
      setTimeout(() => {
        setverifystatus("empty")
      }, 4000);
    }
  }

  useEffect(() => {
    checkVerify()
  }, [])

  const onLogin = async () => {
    try {
      if (email.current.value === "") {
        setemailerr("Please Enter Email")
      } else if (password.current.value === "") {
        setpasserr("Please Enter Password")
      } else {
        const { data } = await Axios.post(`/users/login`, {
          email: email.current.value,
          password: password.current.value
        })
        if (data.success === true && data.result.user.f2A_Status === "false") {
          window.localStorage.setItem('Mellifluous', data.result.token)
          toast.success(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate(`${Const.weblink}/`)
          }, 2000)
        } else {
          window.localStorage.setItem('Mellifluous', data.result.token)
          toast.success(data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate(`${Const.weblink}/two-factor-auth`)
          }, 2000)
        }
      }
    } catch ({ response }) {
      if (response?.data?.message === 'Please Verify Email') {
        setemailerr(response?.data?.message)
      } else if (response?.data?.message === "Invalid Email") {
        setemailerr(response?.data?.message)
      } else if (response?.data?.message === 'Wrong Password') {
        setpasserr(response?.data?.message)
      } else if (response?.data?.message === "Please Enter Valid Email") {
        setemailerr(response?.data?.message)
      }
    }
  }

  return (
    <div className='login-main-outer'>
      <ToastContainer />
      <div className='login-page-main'>
        <div className='login-page-main-inner'>
          <h6>Login</h6>
          {
            verifystatus === "empty" ? <></> :
              <>
                {verifystatus === "Verified" ?
                  <>
                    <div className='emailsuccess' >
                      <p className='emailtext' >Email verified successfully</p>
                    </div>
                  </> :
                  <>
                    <div className='emailerror' >
                      <p className='emailtext' >link Expired</p>
                    </div>
                  </>}
              </>
          }
          <div className='email-password-block'>
            <label>Email</label>
            <div className='cmn-input-cls'>
              <input type='text' placeholder='example@gmail.com' ref={email} onChange={() => { setemailerr(null) }} />
              {emailerr !== null ? <div style={{ textAlign: "center", color: "white" }} >{emailerr}</div> : <></>}
            </div>
          </div>
          <div className='email-password-block'>
            <label>Password</label>
            <div className='cmn-input-cls'>
              <input type={visibile ? "text" : "password"} placeholder='Password' ref={password} onChange={() => { setpasserr(null) }} />

              {passerr !== null ? <div style={{ textAlign: "center", color: "white" }}>{passerr}</div> : <></>}
            </div>
          </div>
          <div className='term1'>
            <input type='checkbox' onClick={() => { setvisible(!visibile) }} />
            <span style={{ marginLeft: "5px" }} >Show Password</span>
          </div>
          <Link to='' className='forgot-password'>Forget password?</Link>
          <div className='login-button-outer'><Button onClick={onLogin} >Login</Button></div>
          <div className='option-for-login-outer'><span className='option-for-login'>OR</span></div>
          <div className='social-icon-login'>
            <div className='social-lgin'><img src={login1} /></div>
            <div className='social-lgin'><img src={login2} /></div>
            <div className='social-lgin'><img src={login3} /></div>
          </div>
          <p className='dont-have-acc'>Don’t have an account? <Link>Sign Up</Link></p>
        </div>
      </div>
    </div>
  )
}

export default Login
