import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import FooterHelp from '../FooterHelp/FooterHelp'
import Container from 'react-bootstrap/esm/Container.js'
import './Terms.css'
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className='terms-page'>
      <HeaderHelp />
      <div className='home-section'>

        <div className='top-head'>
          <h5 className='terms-head'>Terms and Conditions</h5>
        </div>

        <Container>
          <div className='terms-condition'>

            <p className='terms-rules'>These Terms and Conditions are effective from 07/11/2022 (version 2.0). All previous Terms and Conditions are cancelled.</p>
            <p className='terms-rules'>IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS</p>
            <div className='intro-full'>
              <div className='intro-head'>
                <p><b>A. INTRODUCTION</b></p>
              </div>
              <p className='terms-rules'>1. By using, visiting and/or accessing any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Mmadness.com website or mobile application or any other websites or applications that we own or operate (the “Website”) and/or registering on the Website, you agree to be bound by (i) these Terms and Conditions; (ii) our <Link to="" className='link-tag'>Privacy Policy;</Link> (iii) our <Link to="" className='link-tag'>Cookies Policy</Link> and (iv) the <Link to="" className='link-tag'>Rules</Link> applicable to our betting or gaming products as further referenced at paragraph 2 below (together the "Terms"), and are deemed to have accepted and understood all the Terms.</p>
              <p className='terms-rules'>Please read the Terms carefully and if you do not accept the Terms, do not use, visit or access any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Website. The Terms shall also apply to all telephone betting and betting or gaming via mobile devices including downloadable applications to a mobile device (as if references to your use of the Website were references to your use of our telephone betting and/or mobile devices betting facilities).</p>
              <p className='terms-rules'>2. Where you play any game, or place a bet or wager, using the Website, you accept and agree to be bound by, the Rules which apply to the applicable products available on the Website from time to time. The Rules can be found under the Help tab of the applicable section of the Website, or more specifically at:</p>
              <ul className='terms-list'>
                <li>(a) the Rules for Sportsbook products are available by<Link to="" className='link-tag'>clicking here;</Link></li>
                <li>(b) the Rules for Casino products are available by<Link to="" className='link-tag'>clicking here;</Link></li>
                <li>(c)  the Rules for Live Casino products are available by<Link to="" className='link-tag'>clicking here;</Link></li>
              </ul>
              <p className='terms-rules'>3. Where reference is made to “Mmadness”, “we”, "our" or “us” in relation to:</p>
              <p className='terms-rules'>(a) Sportsbook products - refers to Hillside (Malta Sports) ENC, a Partnership incorporated in Malta (registration number P2057) with its registered office at Office 1/2373, Level G, Quantum House, 75 Abate Rigord Street, Ta’ Xbiex XBX 1120. Hillside (Malta Sports) ENC is licensed and regulated by the Malta Gaming Authority (MGA) under licence number MGA/CRP/531/2018/09; and</p>
              <p className='terms-rules'>(b) Gaming products - refers to Hillside (Malta Gaming) ENC, a Partnership incorporated in Malta (registration number P2058) with its registered office at Office 1/2373, Level G, Quantum House, 75 Abate Rigord Street, Ta’ Xbiex XBX 1120.Hillside (Malta Gaming) ENC is licensed and regulated by the Malta Gaming Authority (MGA) under licence number MGA/CRP/531/2018/08.</p>
              <p className='terms-rules'>4. We may need to change the Terms from time to time for a number of reasons (including to comply with applicable laws and regulations, and regulatory requirements). All changes will be published on the Website. The most up to date Terms will be available on the Website. When a material change is carried out on the Terms and Conditions the customer will be requested to accept the new terms and conditions before proceeding. Once accepted, the new terms and conditions will come into effect thereon. Should you, due to any possible changes, not wish to continue using the services of Mmadness anymore, you can withdraw all of your available funds and close the account.
              </p>
              <p className='terms-rules'>5. Reference to "you", "your" or the "customer" is reference to any person using the Website or the services of Mmadness and/or any registered customer of Mmadness.</p>
              <p className='terms-rules'>6. As you are aware, the right to access and/or use the Website (including any or all of the products offered via the Website) may be illegal in certain countries. You are responsible for determining whether your accessing and/or use of the Website is compliant with applicable laws in your jurisdiction and you warrant to us that gambling is not illegal in the territory where you reside.</p>
              <p className='terms-rules'>7. Mmadness is committed to providing excellent customer service. As part of that commitment, Mmadness is committed to supporting responsible gambling. For further details, please click here. Although Mmadness will use its reasonable endeavours to enforce its responsible gambling policies, Mmadness does not accept any responsibility or liability if you nevertheless continue gambling and/or seek to use the Website with the intention of deliberately avoiding the relevant measures in place and/or Mmadness is unable to enforce its measures/policies for reasons outside of Mmadness’s reasonable control.</p>
            </div>

            <div className='intro-full'>
              <div className='intro-head'>
                <p><b>B. YOUR Mmadness ACCOUNT</b></p>

              </div>
              <div>
                <p><b>1. Application</b></p>
                <p className='terms-rules'>1.1 All applicants must be over 18 years of age and of legal capacity to place a bet/wager or register with Mmadness. Mmadness reserves the right to ask for proof of age from any customer and suspend their account until satisfactory documentation is provided. Mmadness takes its responsibilities in respect of under age and responsible gambling very seriously (for further details, please <a href="" className='link-tag'>click here</a>).</p>
                <p className='terms-rules'>1.2 All information supplied when registering with the site MUST be your information and be accurate and complete in all respects. Where this is not the case, we reserve the right to suspend the account and treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void). Where an account is suspended, the relevant customer should<a href="" className='link-tag'>Contact Us.</a></p>
                <p className='terms-rules'>1.3 Mmadness may confirm a customer’s address by posting an address verification letter to the customer. All correspondence will be discreet and the envelope will not display any reference to Mmadness. When such correspondence is initiated, all offers and withdrawal requests may remain pending until the address has been confirmed as correct.</p>
                <p className='terms-rules'>1.4 By accepting the Terms and/or registering to use the Website you hereby agree that we shall be entitled to conduct any and all such identification, credit and other verification checks from time to time that we may require and/or are required by applicable laws and regulations and/or by the relevant regulatory authorities for use of the Website and our products generally. You agree to provide all such information as we require in connection with such verification checks. We shall be entitled to suspend or restrict your account in any manner that we may deem to be appropriate, until such time as the relevant checks are completed to our satisfaction.</p>
                <p className='terms-rules'>1.5 As part of the registration process, we may supply your information details to authorised credit reference agencies to confirm your identity and payment card details. You agree that we may process such information in connection with your registration.</p>
                <p className='terms-rules'>1.6 Customers may open only one account. Should we identify any customer with more than one account we reserve the right to treat any such accounts as one joint account.
                </p>
                <p className='terms-rules'>1.7 Customers must keep their registration and account details up to date. This, and your account information, may be amended in the Personal section of the Website. If you require any assistance, please <Link to="" className='link-tag'>Contact Us.</Link>
                </p>
              </div>

              <div>
                <p><b>2. Account Details</b></p>
                <p className='terms-rules'>2.1 Mmadness allows all its customers to choose their own Username and Password combination for their account and also a 4 digit Passcode for accessing Mmadness apps on their mobile devices. Customers must keep this information secret and confidential as you are responsible for all bets/wagers placed on your account and any other activities taking place on your account.
                </p>

                <p className='terms-rules'>2.2 Bets will stand if your Username and Password have been entered correctly or if your account has been accessed via Touch ID, Fingerprint log in, Face ID, Passcode or the ‘Keep me logged in’ facility (whether or not authorised by you), subject to there being sufficient funds in the account.</p>

                <p className='terms-rules'>2.3 If, at any time, you feel a third party is aware of your Username, Password and/or Passcode you should change it immediately via the Website. Should you forget part or all of your combination, please <Link to="" className='link-tag'>Contact Us.</Link></p>
                <p className='terms-rules'>2.4 If you activate Touch ID, Fingerprint log in, Face ID, Passcode and/or the ‘Keep me logged in’ facility and feel that a third party could be accessing your account via any of these methods then you should immediately disable Touch ID, Fingerprint log in, Face ID, Passcode and/or the ‘Keep me logged in’ facility from all of your devices and <Link to="" className='link-tag'>Contact Us</Link>. You are responsible for the security of your device(s) and for all bets/wagers placed on your account and any other activities taking place on your account.
                </p>
                <p className='terms-rules'>2.5 For bets placed by telephone, you are responsible for all transactions where your name and account number or name and username are correctly quoted (whether or not authorised by you). If you nominate another person as an authorised user of your account, you shall be responsible for all transactions such person makes using the relevant account details. Should you lose your account details or feel that someone else may have your account details, please <Link to="" className='link-tag'>Contact Us.</Link></p>
                <p className='terms-rules'>2.6 Please note that cardholder details and any other sensitive data should never be sent to us by unencrypted email.
                </p>
                <p className='terms-rules'>2.7 The current balance and transaction history of your account may be viewed at any time once you have logged into your account on the Website.
                </p>
              </div>

              <div>
                <p><b>3. Personal Details</b></p>
                <p className='terms-rules'>3.1 We will comply with applicable data protection laws in respect of the personal information you supply to us. Your personal information is processed in accordance with our Privacy Policy, a copy of which is available by <Link to="" className='link-tag'>clicking here.</Link >
                </p>
              </div>

              <div>
                <p><b>4. Suspension and Closure</b></p>
                <p className='terms-rules'>4.1 If you want to close your account, please Contact Us. Any negative balance on your account will fall immediately due and payable to Mmadness, and your account will not be closed until the relevant amount owed to Mmadness is paid in full.
                </p>
                <p className='terms-rules'>4.2 Mmadness reserves the right to close or suspend your account at any time and for any reason. Without limiting the preceding sentence, Mmadness shall be entitled to close or suspend your account if:
                </p>
                <ul className='terms-list'>
                  <li>(a) Mmadness considers that you have used the Website in a fraudulent manner or for illegal and/or unlawful or improper purposes</li>
                  <li>(b) Mmadness considers that you have used the Website in an unfair manner, have deliberately cheated or taken unfair advantage of Mmadness or any of its customers or if your account is being used for the benefit of a third party;
                  </li>
                  <li>(c)  Mmadness considers that you have opened or are using any additional accounts to conceal your activity or to avoid measures put in place on other active or inactive accounts;</li>
                  <li>(d) Mmadness considers that you have deliberately provided incomplete or inaccurate information when registering with the site or during of our verification processes;

                  </li>
                  <li>(f) Mmadness considers that any of the events referred to in (a) to (e) above may have occurred or are likely to occur; or

                  </li>
                  <li>(g) you become bankrupt;
                  </li>
                  <li>(h) your account is deemed to be dormant and its balance is, or reaches zero or is otherwise closed in accordance with paragraph B.5.1 below.

                  </li>
                </ul>

                <p className='terms-rules'>4.3 If Mmadness closes or suspends your account for any of the reasons referred to in (a) to (g) above, you shall be liable for any and all claims, losses, liabilities, damages, costs and expenses incurred or suffered by Mmadness (together “Claims”) arising therefrom and shall indemnify and hold Mmadness harmless on demand for such Claims. In the circumstances referred to in (a) to (e) above (“Prohibited Events”), Mmadness shall also be entitled to void any bets or other activity and withhold and/or retain any and all amounts which would otherwise have been paid or payable to you (including any winnings, Bet Credits or bonus payments) that are attributable to a Prohibited Event or up to an amount no greater than any amount previously withdrawn by you where that amount withdrawn is attributable to a Prohibited Event.
                </p>

              </div>



              <div>
                <p><b>5. Third Party Content</b></p>
                <p className='terms-rules'>5.1 Mmadness receives feeds, commentaries and content from a number of suppliers. Certain third party product providers may require you to agree to additional terms and conditions governing the use of their feeds, commentaries and content. If you do not accept the relevant third party terms and conditions, do not use the relevant feeds, commentaries or content.
                </p>
                <p className='terms-rules'>5.2 Mmadness does not accept any liability in respect of any third party feeds, commentaries and content.
                </p>
                <p className='terms-rules'>5.3 The end user terms and conditions relating to your use of the Racing TV feeds, commentaries and content are set out in Appendix Three to these Terms and Conditions.
                </p>

                <p className='terms-rules'>5.4 Mmadness does not allow any employee, anyone else in any way connected to such employee or anyone otherwise connected to a third party service provider (to be determined in Mmadness’s absolute discretion) to bet/wager on any market or event where the third party service provider is providing a service to Mmadness. Mmadness will void any bet/wager where it determines in its absolute discretion that such betting/wagering has taken place.
                </p>

                <p className='terms-rules'>5.5 Where the Website contains links to third party websites and resources, these links are provided for your information only. Mmadness has no control over the content of these sites or resources, and accepts no liability for them or for any loss or damage that may arise from your use of them. The inclusion of a link to a third party website does not constitute an endorsement of that third party’s website, product or services (if applicable).
                </p>
              </div>

              <div>
                <p><b>6. Errors</b></p>

                <p className='terms-rules'>6.1 Mmadness will not be liable for any errors in respect of bets or wagers including where: (i) there is an Obvious Error in the relevant odds/spreads/handicap/totals/Cash Out/Edit Bet amount displayed by Mmadness; (ii) Mmadness continues to accept bets or wagers on closed or suspended markets; (iii) Mmadness incorrectly calculates or pays a settlement amount, including where a bet is Cashed Out for the full settlement amount; or a bet is made void incorrectly, where ‘Void if player does not start’ was selected at bet placement; or (iv) any error occurs in a random number generator or pay tables included, incorporated or used in any game or product.
                </p>
                <p className='terms-rules'>6.2 Incorrect Price - Prior to the start of an event, In-Play or after the event, where an Obvious Error is identified any bets will stand and be settled at the Mmadness revised price. Where a revised price is deemed less than 1/1000 then bets will be void.

                </p>
                <p className='terms-rules'>Where there is sufficient time before the start of an event, Mmadness will endeavour to contact the customer and may in our absolute discretion allow the option of cancelling the bet.

                </p>

                <p className='terms-rules'>6.3 Incorrect Count/Line/Spread/Handicap/Total - Prior to the start of an event, In-Play or after the event, where an Obvious Error is identified any bets will stand and be settled at the count, line, spread, handicap or total taken at the Mmadness revised price except for the following situations:
                </p>
                <ul className='terms-list'>
                  <li>i) Where a revised price is deemed less than 1/1000 then bets will be void.</li>
                  <li>ii) Any bet placed on a count, line, spread, handicap or total where the outcome is already known when the bet was placed will be void..</li>
                </ul>
                <p className='terms-rules'>6.4 Incorrect Cash Out or Edit Bet Amount - Prior to the start of an event, In-Play or after the event, any bets that have been Cashed Out by the customer or edited by the customer using our Edit Bet feature, where the Cash Out or Edit Bet amount is incorrect (due to an error in the underlying price), will stand and be resettled at the correct amount.
                </p>
                <p className='terms-rules'>Where a selection is Cashed Out following an incorrect result the selection will be resettled on the correct result.
                </p>

                <p className='terms-rules'>6.5 Incorrect Void – If ‘Void if player does not start’ was selected at bet placement (specific to Goalscorer markets) and a bet is incorrectly made void where the selected player has actually started the event, the original bet will stand and will be resettled on the correct outcome.
                </p>

                <p className='terms-rules'>6.6 Where there is sufficient time before the start of an event, Mmadness will endeavour to contact the customer and may in our absolute discretion allow the option of cancelling the bet.
                </p>

                <p className='terms-rules'>6.8 Incorrect Market - If an incorrect market is quoted for any match or event, bets placed on that market will be void; other markets may also be void. Such determination to be in Mmadness’s absolute discretion.
                </p>

                <p className='terms-rules'>6.9 Late Bets - If for any reason a pre-event bet is inadvertently accepted after a match or event has commenced, bets will be settled as follows:
                </p>

                <ul className='terms-list'>
                  <li>i) If the event and market is covered In-Play then bets will stand at the revised price at the time the bet was placed (where a revised price is deemed less than 1/1000 then bets will be void), unless the outcome is already known in which case such bets will be void.
                  </li>
                  <li>ii)If the event or market is not covered In-Play then the bet will stand as long as the selected participant or team has not gained a material advantage (e.g. a score, sending off for the other team etc). Where a material advantage has been gained Mmadness reserve the right to void the bet, win or lose. Any bet placed where the outcome is already known, including In-Play, will be made void.</li>
                </ul>
                <p className='terms-rules'>6.10 Obvious Error - Maximum Winnings - The maximum amount which can be won for a bet placed where there is an Obvious Error and a revised price is £10,000, unless the expected winning amount of the original bet was greater than £10,000. In such circumstances, the winning amount of the bet placed at the revised price will equal that of the original bet with any excess stake becoming void.
                </p>

              </div>


              <div>
                <p><b>7. Other</b></p>
                <p className='terms-rules'>7.1 Mmadness actively monitors traffic to and from the Website. Mmadness reserves the right in its sole discretion to block access where evidence indicative of automated or robotic activity is found.

                </p>
                <p className='terms-rules'>7.2 Mmadness reserves the right to restrict access to all or certain parts of the Website in respect of certain jurisdictions.
                </p>
                <p className='terms-rules'>7.3 Mmadness may alter or amend the products offered via the Website at any time and for any reason.

                </p>

                <p className='terms-rules'>7.4 From time to time, all or part of the Website may be unavailable for use by you because of our maintenance of the Website and/or alteration or amendment of any of the Website products.
                </p>

                <p className='terms-rules'>7.5 A malfunction or error in a gaming product will void all applicable stakes and returns.
                </p>
              </div>

              <div>
                <p><b>F. OUR LIABILITY</b></p>
                <p className='terms-rules'>1. Mmadness does not accept any liability for any damages, liabilities or losses which are deemed or alleged to have arisen out of or in connection with the Website or its content (including delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person's misuse of the Website or its content or any errors or omissions in content).
                </p>

                <p className='terms-rules'>2. While Mmadness endeavours to ensure that the information on the Website is correct, Mmadness does not warrant the accuracy or completeness of the information and material on the Website. The Website may contain typographical errors or other inaccuracies, or information that is out of date. Mmadness is under no obligation to update such material. The information and material on the Website is provided “as is”, without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, Mmadness provides you with the Website on the basis that Mmadness excludes all representations, express or implied warranties, conditions and other terms which but for these terms and conditions might have effect in relation to the Website.
                </p>
                <p className='terms-rules'>3. Mmadness’s total aggregate liability to you under or in connection with these Terms and Conditions does not exceed:
                </p>

                <ul className='terms-list'>
                  <li>(a) the value of the bets and/or wagers you placed via your account in respect of the relevant bet/wager or product that gave rise to the relevant liability; and
                  </li>
                  <li>(b) the amount of applicable monies, where such monies have been misplaced by us.
                  </li>
                  <li>(c) £10,000 in respect of any other liability.
                  </li>
                </ul>

                <p className='terms-rules'>4. Mmadness shall not be liable, in contract, tort (including negligence) or for breach of statutory duty or in any other way for any of the following (whether incurred directly or indirectly):
                </p>

                <ul className='terms-list'>
                  <li>(a) loss of profits;
                  </li>
                  <li>(b) loss of business;</li>
                  <li>(c) loss of revenue;</li>
                  <li>(d) loss of opportunity;</li>
                  <li>(e) loss of data;</li>
                  <li>(f) loss of goodwill or reputation; or</li>
                  <li>(g) any special, indirect or consequential losses;</li>
                </ul>

                <p className='terms-rules'>whether or not such losses were within the contemplation of the parties at the date of these terms and conditions.
                </p>

                <p className='terms-rules'>5. Nothing in this Section F shall limit Mmadness's liability to pay the customer winnings or other sums properly owing to it, subject always to terms and conditions set out herein and the maximum winnings on products in Appendix Two to these Terms and Conditions.
                </p>

                <p className='terms-rules'>6. Nothing in these Terms and Conditions shall exclude or limit Mmadness’s liability for:
                </p>
                <ul className='terms-list'>
                  <li>(i) death or personal injury caused by Mmadness’s negligence;
                  </li>
                  <li>(ii) fraud or fraudulent misrepresentation; or</li>
                  <li>(iii) any liability which cannot be excluded or limited under applicable law</li>

                </ul>
              </div>


              <div>
                <p><b>G. OUR INTELLECTUAL PROPERTY RIGHTS</b></p>
                <p className='terms-rules'>1. The contents of the Website are protected by international copyright laws and other intellectual property rights. The owner of these rights is Mmadness, its affiliates or other third party licensors.
                </p>
                <p className='terms-rules'>2. All product and company names and logos mentioned on the Website are the trade marks, service marks or trading names of their respective owners, including Mmadness.
                </p>
                <p className='terms-rules'>3. Except to the extent required to use a product for the purpose of placing bets or wagers, no part of the Website may be reproduced or stored, modified, copied, republished, uploaded, posted, transmitted or distributed, by any means or in any manner, or included in any other website or in any public or private electronic retrieval system or service including text, graphics, video, messages, code and/or software without our express prior written consent.

                </p>

                <p className='terms-rules'>4. If you make use of a feature that allows you to upload material, information, comments, postings or other content to the Website ("User Content"), then the User Content will be considered to be non-confidential and non-proprietary and Mmadness has the right to use, copy, distribute and disclose to third parties any User Content for any purpose. Mmadness also has the right to disclose your identity to any third party who is claiming that any User Content posted or uploaded by you to the Website constitutes a violation of their intellectual property rights or of their right to privacy. Mmadness has the right to remove, amend or edit any User Content you make on the Website.
                </p>

                <p className='terms-rules'>5. Any commercial use or exploitation of the Website or its content is strictly prohibited.
                </p>
              </div>

              <div>
                <p><b>H. OTHER PROVISIONS</b></p>
                <p className='terms-rules'>1. These Terms and Conditions, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website constitute the entire agreement and understanding of the parties and supersede any previous agreement between the parties relating to their subject matter. You acknowledge and agree that in entering into and agreeing to these Terms and Conditions, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website you do not rely on, and shall have no remedy in respect of, any statement, representation, warranty, understanding, promise or assurance (whether negligently or innocently made) of any person (whether party to this agreement or not) other than as expressly set out therein. Nothing in this clause shall operate to limit or exclude any liability for fraud or fraudulent misrepresentation.

                </p>
                <p className='terms-rules'>2. In no event will any delay, failure or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or remedy conferred by or arising under these Terms and Conditions or by law, be deemed to be or construed as a waiver of that or any other right, power, privilege, claim or remedy in respect of the circumstances in question, or operate so as to bar the enforcement of that, or any other right, power, privilege, claim or remedy, in any other instance at any time or times subsequently.
                </p>
                <p className='terms-rules'>3. The rights and remedies provided by these Terms and Conditions are cumulative and (unless otherwise provided in these Terms and Conditions) do not exclude any other rights or remedies available in law.
                </p>

                <p className='terms-rules'>4. If any provision of these Terms and Conditions is found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability shall not affect the other provisions of these Terms and Conditions which shall remain in full force and effect.
                </p>

                <p className='terms-rules'>5. You shall execute or cause to be executed all documents and do or cause to be done all further acts and things consistent with the terms of these Terms and Conditions that Mmadness may from time to time reasonably require in order to vest in and secure to Mmadness the full benefit of rights and benefits to be transferred or granted to Mmadness under these Terms and Conditions and for the protection and enforcement of the same and otherwise to give full effect to the terms of these Terms and Conditions.
                </p>

                <p className='terms-rules'>6. Nothing in these Terms and Conditions shall create or be deemed to create a partnership, joint venture or principal-agent relationship between the parties and no party shall have authority to bind any other in any way unless expressly provided otherwise in these Terms and Conditions.
                </p>

                <p className='terms-rules'>7. Mmadness shall not be in breach of these Terms and Conditions nor liable for delay in performing, or failure to perform, any of its obligations if such delay or failure results from events, circumstances or causes beyond its reasonable control including (without limitation) any telecommunications network failures, power failures, failures in third party computer hardware or software, fire, lightning, explosion, flood, severe weather, industrial disputes or lock-outs, terrorist activity and acts of government or other competent authorities. In such circumstances the time for performance shall be extended by a period equivalent to the period during which performance of the obligation has been delayed or failed to be performed.
                </p>

                <p className='terms-rules'>8. Mmadness may assign, transfer, charge, sub-license or deal in any other manner with these Terms and Conditions, or sub-contract any of its rights and obligations under these Terms and Conditions, to any party including any company within the Mmadness group.
                </p>

                <p className='terms-rules'>9. Any notice to be given under these Terms and Conditions must be in writing, in English and may be served by hand delivery, first class post, recorded delivery or registered post or airmail, email or fax to: (a) in the case of Mmadness, the address of the relevant Mmadness company as set out at the beginning of these Terms and Conditions or as displayed on the Website; and (b) in respect of notices given by Mmadness to you, pursuant to the customer registration procedure (including any amendment to those details you have notified to Mmadness). Any notice shall be deemed to have been received: (a) if delivered by hand, at the time of delivery; (b) if sent by first class post, recorded delivery or registered post, at 09.30 (GMT) on the second clear day after the date of posting; (c) if sent by pre-paid registered airmail, at 09.30 (GMT) on the fifth clear day after the date of posting; (d) if sent by email, at the time of sending; and (e) if sent by fax, at the time of transmission by the sender.
                </p>

                <p className='terms-rules'>10. The Appendices, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website form an integral part of these Terms and Conditions and shall have effect as if set out in full in the body of these Terms and Conditions. In the event of any inconsistency between the main body of these Terms and Conditions and the Appendices, the Privacy Policy, the Cookies Policy, the Rules and/or any document expressly referred to in them and any guidelines or rules posted on the Website, unless stated otherwise, the main body shall prevail.
                </p>

              </div>

              <div>
                <p><b>I. COMPLAINTS, DISPUTES, GOVERNING LAW AND JURISDICTION</b></p>
                <p className='terms-rules'>1. Should there be any claim or dispute arising from a past or current transaction, please Contact Us. If Mmadness is unable to settle the dispute, either party is entitled to refer the dispute to an arbiter, such as the eCommerce and Online Gaming Regulation and Assurance (eCOGRA) or via the Online Dispute Resolution (ODR) platform, whose decision will be final (save in respect of any manifest error) subject to full representation given to all parties involved. No dispute regarding any bet/wager will result in litigation, court action or objection to a bookmaker’s licence or permit (including any remote operator’s licence or personal licence) unless Mmadness fails to implement the decision given by arbitration. You may also escalate a complaint to the Malta Gaming Authority (MGA) via www.mga.org.mt/support/online-gaming-support or by sending the MGA an email to support.mga@mga.org.mt.
                </p>
                <p className='terms-rules'>2. For further information on eCOGRA and ODR, <Link to="" className='link-tag'>click here.</Link>
                </p>
                <p className='terms-rules'>3. Settlement of bets/wagers on American sports: In all cases bets/wagers on American sports will be settled using statistics and results as provided by each sport's governing body (obvious errors excepted). The relevant governing bodies are as follows, NFL, NCAAF, CFL, NBA, NCAAB, NHL, MLB, NASCAR, MLS and the PGA tour.

                </p>

                <p className='terms-rules'>4. Subject to paragraph B.3.1 above, these Terms and Conditions and any dispute or claim arising out of or in connection with it or its subject matter, whether of a contractual or non-contractual nature, shall be governed by and construed in accordance with the laws of England and Wales.
                </p>

                <p className='terms-rules'>5. By accepting these Terms and Conditions and/or placing bets or wagers and/or making use (whether authorised or not) of the facilities offered by Mmadness (whether through the Website or otherwise) and/or by using, visiting and/or accessing any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Website, you irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute which may arise out of or in connection with these Terms and Conditions. Notwithstanding the foregoing, Mmadness shall be entitled to bring a claim against a customer in the court of the customer's country of domicile.
                </p>
              </div>

              <div>
                <p><b>Appendix One</b></p>
                <p><b>CURRENCY OPTIONS AVAILABLE AT Mmadness</b></p>
                <p className='terms-rules'>Restrictions may apply depending upon your country of residence and/or chosen payment method.
                </p>
                <ul className='terms-list'>
                  <li>Euros (EUR)</li>
                  <li>Indian Rupees (INR)</li>
                  <li>UK Pounds (GBP)</li>
                  <li>United States Dollars (USD)</li>
                </ul>
              </div>

              <div>
                <p><b>Appendix Two</b></p>
                <p><b>BETTING COVERAGE</b></p>
                <p className='terms-rules'>THE SUBSEQUENT PROVISIONS OF THIS APPENDIX TWO ARE SUBJECT TO THE RULES IN RESPECT OF THE RELEVANT SPORT, EVENT OR PRODUCT THAT YOUR BET/WAGER RELATES TO.
                </p>

                <p><b>American Sports</b></p>
                <p className='terms-rules'>We accept wagers on all North American sports such as Football, Baseball, Basketball, Hockey, Golf, Nascar and CFL etc.
                </p>

                <p><b>Horse Racing</b></p>
                <p className='terms-rules'>We accept wagers for all meetings held under the rules of the UK Jockey Club, Irish Turf Club and the equivalent international bodies only, as well as all meetings in Australia and New Zealand covered by an Australasian TAB.
                </p>

                <p><b>Greyhound Racing</b></p>
                <p className='terms-rules'>Greyhound bets are accepted for all BAGS (Bookmakers’ Afternoon Greyhound Service) meetings and other meetings whose card is published on the Website. We also accept bets for all meetings in Australia and New Zealand covered by an Australasian TAB.
                </p>

                <p><b>Harness Racing</b></p>
                <p className='terms-rules'>We accept bets for all meetings in Australia and New Zealand covered by an Australasian TAB.
                </p>

                <div>
                  <p><b>Sports and Special Event Betting</b></p>
                  <p className='terms-rules'>We accept bets/wagers for sporting and other special events that are advertised on the Website. All such bets/wagers are subject to the rules applicable to each sport, e.g. Soccer, Tennis, Boxing, etc. In a match between two teams where the participants are named, if any player is replaced prior to the match starting, all match bets placed prior to the change will be void, win or lose. Our general betting rules will apply to any situations not covered by the special rules.
                  </p>

                  <p className='terms-rules'>Where the official result of a market is a tie and no price was quoted for that eventuality, all bets on participants involved in the tie will be void and stakes will be returned.

                  </p>

                  <p className='terms-rules'>The result of a match or event will be determined on the day of its conclusion for betting purposes, subject to confirmation by the relevant sport's governing body. Any subsequent enquiry that may result in an overturned decision will not be recognised by Mmadness, and original settlement of bets will stand.
                  </p>

                  <p className='terms-rules'>If the venue for a sporting event is changed, all bets placed based on the original venue will be made void and stakes refunded, unless otherwise stated.
                  </p>

                  <p className='terms-rules'>Odds on coupon - For specified sports we have a coupon, listing odds on betting opportunities within specified time frames of the next 3/6/12/24/48/72 hours. Bets stand irrespective of fixture time changes.
                  </p>
                </div>

                <div>
                  <p><b>MAXIMUM WINNINGS</b></p>
                  <p className='terms-rules'>The maximum winnings in any 24 hour period for each sport are detailed below and are exclusive of stake. The maximum winnings are quoted in Pounds Sterling as a base currency in order to ensure consistency across the world but currency equivalents apply for actual payouts.
                  </p>

                  <p className='terms-rules'>Where a multiple bet has been placed which involves events with different maximum winnings limits then the lowest limit will apply. All maximum winnings limits apply to any one customer, or group of customers acting together, who have placed bets containing the same selections, including where placed in a series of bets, at a range of prices, over a number of days using different betting accounts and/or across different betting channels. Should we have reason to believe that a number of bets have been placed this way, the total payment of all those bets combined will be limited to one single maximum winnings payout.

                  </p>

                </div>

                <div>
                  <p><b>Soccer</b></p>
                  <p className='terms-rules'>- £2,000,000 for all Men’s full International matches and the following competitions - English Premier League, Championship, League 1, League 2, FA Cup, Spanish La Liga, Italian Serie A, German Bundesliga I, French Ligue 1, UEFA Champions League and UEFA Europa League.
                  </p>

                  <p className='terms-rules'>£500,000 for the following competitions Australian A-League, Scottish Premiership, English National League, Norwegian Tippeligaen, Portuguese Primeira Liga, Swedish Allsvenskan and English League Cup and USA MLS.
                  </p>

                  <p className='terms-rules'>£250,000 for the following competitions Spanish Segunda, Italian Serie B, German Bundesliga II, French Ligue 2, Danish Superliga, Dutch Eredivisie, Finnish Veikkausliiga, Japanese J-League, Norwegian First Division, Swedish Superettan, Argentinian Liga Profesional, Brazilian Serie A and Mexican Liga MX.

                  </p>

                  <p className='terms-rules'>- £100,000 for the following competitions - Austrian Bundesliga, Belgian First Division A, Danish Division 1, Scottish Championship, Scottish League One, Scottish League Two, Swiss Super League, Greek Super League and Turkish Super League.
                  </p>

                  <p className='terms-rules'>- £50,000 for all other soccer competitions and for qualifying rounds for all competitions including UEFA Champions League qualifying rounds, Europa League qualifying rounds and FA Cup qualifying rounds (does not apply to Men’s full Internationals qualifying rounds).

                  </p>

                  <p className='terms-rules'>- £25,000 for the following markets - Manager Markets, Player to Make Squad, Player to Start Match, Player to be Booked/Sent Off, Player of the Tournament and Transfer Specials.
                  </p>

                  <p className='terms-rules'>Futsal, Beach Soccer and Indoor Soccer are excluded from these limits.
                  </p>

                </div>

                <div>
                  <p><b>Horse Racing - UK and ROW</b></p>

                  <p className='terms-rules'>- £1,000,000 where all the selections are for Horse Racing in the UK and Ireland when an Industry SP is declared.
                  </p>

                  <p className='terms-rules'>- £250,000 where all the selections are for overseas racing, when an Industry SP is declared.
                  </p>

                  <p className='terms-rules'>- £100,000 for all other UK and Ireland horse racing, where any of the selections do not have an industry SP declared.
                  </p>

                  <p className='terms-rules'>- £100,000 for all other overseas horse racing.

                  </p>

                  <p className='terms-rules'>- £100,000 for all other horse racing meetings displayed on our site with no Industry SP declared
                  </p>

                  <p className='terms-rules'>- £100,000 for the following markets; Cover Bet(s), To Be Placed, Not to Be Placed, Betting Without Favourite(s), Not To Win, Jollies And Rags, Match Bets and Each Way Extra.
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.
                  </p>
                </div>


                <div>
                  <p><b>Horse Racing - Australia</b></p>

                  <p className='terms-rules'>The following applies to all racing covered by a TAB.
                  </p>

                  <p className='terms-rules'>Metropolitan Racing
                  </p>

                  <p className='terms-rules'>- £1,000,000 for all Win and Place
                  </p>

                  <p className='terms-rules'>- £250,000 for all Exotic bets

                  </p>

                  <p className='terms-rules'>Non-Metropolitan
                  </p>

                  <p className='terms-rules'>- £350,000 for all Win and Place
                  </p>

                  <p className='terms-rules'>- £100,000 for all Exotics bets
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.
                  </p>

                </div>

                <div>
                  <p><b>Horse Racing - New Zealand</b></p>

                  <p className='terms-rules'>The following applies to all racing covered by a TAB.
                  </p>

                  <p className='terms-rules'>- £100,000 for all Win and Place

                  </p>

                  <p className='terms-rules'>- £20,000 for all Exotics bets
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.

                  </p>

                </div>

                <div>
                  <p><b>Harness Racing - Australia, New Zealand and Asia</b></p>

                  <p className='terms-rules'>The following applies to all racing covered by a TAB.
                  </p>

                  <p className='terms-rules'>- £100,000 for all Win and Place

                  </p>

                  <p className='terms-rules'>- £20,000 for all Exotics bets
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.
                  </p>

                </div>

                <div>
                  <p><b>Greyhounds - UK and ROW</b></p>

                  <p className='terms-rules'>- £1,000,000 for Race Winner Singles, Forecast Singles, Tricast Singles, and Ante-Post Race Winner Singles; at all BAGS, BEGS, SIS and RPGTV meetings and those covered Live on Sky Sports (excluding Irish tracks which would be subject to £100,000).
                  </p>

                  <p className='terms-rules'>- £100,000 for all Multiple bets that include any Race Winner (including Ante-Post), Forecast or Tricast selections; at all BAGS, BEGS, SIS and RPGTV meetings and those covered Live on Sky Sports (excluding Irish tracks which would be subject to £25,000).

                  </p>

                  <p className='terms-rules'>- £25,000 for all markets on non BAGS, BEGS, SIS and RPGTV meetings at Crayford, Doncaster, Henlow, Hove, Kinsley, Monmore, Newcastle, Nottingham, Peterborough, Poole, Romford, Sheffield, Sunderland, Swindon and Yarmouth.
                  </p>

                  <p className='terms-rules'>- £25,000 for all other meetings that are published on the Mmadness website (except exceptions stated below).
                  </p>

                  <p className='terms-rules'>£10,000 for all markets on non BAGS, BEGS, SIS and RPGTV meetings at Belle Vue, Central Park, Harlow, Perry Barr,Towcester and any meetings from USA and the Americas.
                  </p>

                  <p className='terms-rules'>- £10,000 for all other Greyhound Racing markets not specified above.
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.
                  </p>

                </div>

                <div>
                  <p><b>Greyhounds - Australia and New Zealand.</b></p>

                  <p className='terms-rules'>The following applies to all racing covered by a TAB.
                  </p>

                  <p className='terms-rules'>- £100,000 for all Win and Place

                  </p>

                  <p className='terms-rules'>- £20,000 for all Exotics bets
                  </p>

                  <p className='terms-rules'>Notwithstanding any of the above, Mmadness reserves the right to void any or all wagers where Mmadness reasonably suspects that they have been made by any individual or group of individuals acting together in an attempt to manipulate tote-based dividends or official starting prices.
                  </p>

                </div>

                <div>
                  <p><b>Boxing/MMA.</b></p>

                  <p className='terms-rules'>- £250,000 for all Boxing markets.
                  </p>

                  <p className='terms-rules'>- £25,000 for all MMA markets.</p>
                </div>

                <div>
                  <p><b>Cricket.</b></p>

                  <p className='terms-rules'>- £250,000 for all markets.
                  </p>

                </div>

                <div>
                  <p><b>Golf.</b></p>

                  <p className='terms-rules'>- £500,000 for all markets.
                  </p>

                </div>


                <div>
                  <p><b>Rugby Union.</b></p>

                  <p className='terms-rules'>- £500,000 for Internationals, World Cup, Six Nations, Four Nations, Premiership Rugby, European Rugby Champions Cup and Super Rugby Outrights and Game Betting markets (2 and 3 way) (excludes Alternatives). Game Betting markets being:-
                  </p>

                  <p className='terms-rules'>Match Handicap, Match Total, Match Result/Winner.
                  </p>

                  <p className='terms-rules'>- £100,000 for all other Rugby Union markets/Competitions not listed.
                  </p>

                </div>

                <div>
                  <p><b>Rugby League.</b></p>

                  <p className='terms-rules'>- £500,000 for Super League, NRL, International and World Cup Outrights and Game Betting markets (2 and 3 way) (excludes Alternatives). Game Betting markets being:-
                  </p>

                  <p className='terms-rules'>Match Handicap, Match Total, Match Result/Winner.
                  </p>

                  <p className='terms-rules'>- £100,000 for all other Rugby League markets/Competitions not listed.
                  </p>

                </div>

                <div>
                  <p><b>Australian Rules</b></p>

                  <p className='terms-rules'>- £250,000 for AFL Outrights and Game Lines (excludes Alternatives). Game Lines being:-
                  </p>

                  <p className='terms-rules'>Match Handicap, Match Total, Match Winner.
                  </p>

                  <p className='terms-rules'>- £25,000 for all other Australian Rules markets/Competitions not listed above.
                  </p>

                </div>

                <div>
                  <p><b>American Football</b></p>
                  <div>
                    <p><b>NFL</b></p>
                    <p className='terms-rules'>- £500,000 for all NFL Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £50,000 for all other NFL markets not listed.
                    </p>
                  </div>

                  <div>
                    <p><b>NCAAF</b></p>
                    <p className='terms-rules'>- £100,000 for all NCAAF Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £25,000 for all other NCAAF markets not listed.
                    </p>
                  </div>

                  <div>
                    <p><b>CFL</b></p>
                    <p className='terms-rules'>- £100,000 for all CFL Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £25,000 for all other CFL markets not listed.
                    </p>
                  </div>

                  <div>
                    <p><b>Other Leagues</b></p>
                    <p className='terms-rules'>- £50,000 for all other competition Game Lines (2 and 3 way) (excludes Alternatives) not listed above. Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £25,000 for all other American Football markets not listed above.
                    </p>
                  </div>

                  <div>
                    <p><b>Tennis</b></p>
                    <p className='terms-rules'>- £500,000 for Outright Tournament Winner, and All Singles Main Draw Match Winner markets from the following events: Men’s Grand Slam Tournaments, ATP World Tour and Davis Cup World Group (inc. World Group Playoff).
                    </p>

                    <p className='terms-rules'>- £250,000 for Outright Tournament Winner, and All Singles Main Draw Match Winner markets from the following events: Women’s Grand Slam Tournaments, WTA Tour and Fed Cup World Group 1 (inc. World Group 1 Playoff).
                    </p>

                    <p className='terms-rules'>- £25,000 for all other Tennis markets/Tournaments not listed above (this includes all ITF, ATP Challenger, WTA 125k, Padel Tennis, all ATP/WTA/Grand Slam/Davis Cup/Fed Cup Doubles and all Qualifying events).
                    </p>
                  </div>

                </div>

                <div>
                  <p><b>Baseball</b></p>
                  <div>
                    <p><b>MLB</b></p>
                    <p className='terms-rules'>- £500,000 for all MLB Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Run Line, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £50,000 for all other MLB markets not listed.
                    </p>
                  </div>

                  <div>
                    <p><b>Other Leagues</b></p>
                    <p className='terms-rules'>- £50,000 for all other Baseball competition Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Run Line, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £10,000 for all other Baseball competition markets.
                    </p>
                  </div>

                </div>

                <div>
                  <p><b>Ice Hockey</b></p>
                  <div>
                    <p><b>NHL</b></p>
                    <p className='terms-rules'>- £500,000 for all NHL Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Puck Line, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £50,000 for all other NHL markets not listed.
                    </p>

                    <p className='terms-rules'> - £100,000 for Finland SM Liga, KHL, Swedish Hockey League, World Championships (Men) and Olympic Games (Men) Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-</p>

                    <p className='terms-rules'>Puck Line, Game Total, Money Line.
                    </p>

                  </div>

                  <div>
                    <p><b>Other</b></p>
                    <p className='terms-rules'>- £25,000 for all other Ice Hockey markets/Leagues/Tournaments not listed above.
                    </p>

                    <p className='terms-rules'>Run Line, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £10,000 for all other Baseball competition markets.
                    </p>
                  </div>

                </div>


                <div>
                  <p><b>Basketball</b></p>
                  <div>
                    <p><b>NBA</b></p>
                    <p className='terms-rules'>- £500,000 for all NBA Championship Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £50,000 for all other NBA markets not listed.
                    </p>

                  </div>

                  <div>
                    <p><b>NCAAB</b></p>
                    <p className='terms-rules'>- £100,000 for all NCAAB Championship Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                    <p className='terms-rules'>- £25,000 for all other NCAAB markets not listed.
                    </p>
                  </div>

                  <div>
                    <p><b>Euroleague/Spain ACB</b></p>
                    <p className='terms-rules'>- £100,000 for all Euroleague/Spain ACB Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                    </p>

                    <p className='terms-rules'>Point Spread, Game Total, Money Line.
                    </p>

                  </div>

                </div>

                <div>
                  <p><b>Other</b></p>
                  <p className='terms-rules'>- £25,000 for all other Basketball markets/competitions not listed above.
                  </p>
                </div>

                <div>
                  <p><b>Darts</b></p>
                  <p className='terms-rules'>- £250,000 for all BDO, PDC and Premier League Match winner and outright tournament markets.
                  </p>

                  <p className='terms-rules'>- £25,000 for all other Darts markets/Tournaments not listed above.
                  </p>
                </div>

                <div>
                  <p><b>Snooker</b></p>
                  <p className='terms-rules'>- £250,000 Match Winner/Outright markets for all Premier League and World Ranking Tournament Matches.

                  </p>

                  <p className='terms-rules'>- £25,000 for all other Snooker markets/Tournaments not listed.
                  </p>
                </div>



                <div>
                  <p><b>Bowls</b></p>
                  <p className='terms-rules'>- £100,000 for all Match Winner and Tournament Outright Bowls markets.
                  </p>

                  <p className='terms-rules'>- £25,000 for all other Bowls markets not listed.
                  </p>
                </div>



                <div>
                  <p><b>Handball</b></p>
                  <p className='terms-rules'>- £100,000 for Champions League (Men and Women), Denmark Ligaen (Men and Women), Spain Liga Asobal (Men), Euro Champs (Men and Women), World Champs (Men and Women) and Olympic Games (Men and Women) Outrights and Game Lines (2 and 3 way) (excludes Alternatives). Game Lines being:-
                  </p>

                  <p className='terms-rules'>Match Handicap, Match Total, Match Result/Winner.
                  </p>

                  <p className='terms-rules'>- £25,000 for all other Handball markets/Leagues/Tournaments not listed.
                  </p>
                </div>

                <div>
                  <p><b>Cycling</b></p>
                  <p className='terms-rules'>- £100,000 for all markets.
                  </p>
                </div>

                <div>
                  <p><b>Trotting</b></p>
                  <p className='terms-rules'>- £100,000 for all markets.
                  </p>
                </div>

                <div>
                  <p><b>Volleyball</b></p>
                  <p className='terms-rules'>- £100,000 for Olympic Games (Men and Women), World Championships (Men and Women), World Cup (Men and Women) and Nations League (Men and Women) Outrights and Match Winner market.
                  </p>
                  <p className='terms-rules'>- £25,000 for all other Volleyball markets/Leagues/Tournaments not listed.
                  </p>
                </div>

                <div>
                  <p><b>Lotto</b></p>
                  <p className='terms-rules'>- £1,000,000 for all markets.
                  </p>
                </div>

                <div>
                  <p><b>Esports</b></p>
                  <p className='terms-rules'>- £25,000 for all markets.
                  </p>
                </div>


                <div>
                  <p><b>Non-Sport, Specials and Novelty Bets</b></p>
                  <p className='terms-rules'>- £25,000 for all non-sports bets and speciality bets (e.g. next manager of a soccer team, Big Brother winner etc).
                  </p>
                  <p className='terms-rules'>All Other Sports Max payout £25,000 (includes Beach Soccer/Indoor Soccer/Futsal/Beach Volleyball/Table Tennis/Kabaddi/Drone Racing and any electronic sports version of a specific sport).
                  </p>
                </div>

                <div>
                  <p><b>Related contingency betting</b></p>
                  <p className='terms-rules'>Multiple bets which combine different selections within the same event are not accepted where the outcome of one affects or is affected by the other.
                  </p>
                  <p className='terms-rules'>For example, in a soccer match involving Arsenal and Everton:
                  </p>
                  <p className='terms-rules'>If Arsenal are 'evens' to win at home to Everton and the correct score odds for Arsenal to win 1-0 is 8/1, by having the double Arsenal to win and the correct score to be 1-0, a customer would be getting 17/1 about an 8/1 chance, for if Arsenal win 1-0 they would automatically have won the match.
                  </p>
                  <p className='terms-rules'>If such a bet is taken in error it will be settled as two single bets with the stake equally divided.
                  </p>
                  <p className='terms-rules'>An exception to this rule is where special odds are offered for the combined eventuality, e.g. First Player to Score/Correct Score Double, known as a Scorecast or where a customer has combined interrelated bets into one bet using Bet Builder.
                  </p>
                  <p className='terms-rules'>When the related parts of a bet are resolved at different times but still within the same tournament, the bet will be settled as instructed, with the price of the second or subsequent selection being determined at each individual stage.
                  </p>
                  <p className='terms-rules'>For example, a double selects:
                  </p>
                  <p className='terms-rules'>Federer to win the semi final of Wimbledon; and

                  </p>
                  <p className='terms-rules'>Federer to win the Wimbledon tournament outright.
                  </p>
                  <p className='terms-rules'>As the odds of the second selection depends on the outcome of the first, the odds of Roger Federer winning the tournament at the time the bet was struck cannot be used to calculate the double, and the correct odds after the initial selection is completed will be attributed instead.
                  </p>
                  <p className='terms-rules'>Multiple bets which combine different selections within different events are also not accepted where the outcome of one affects or is affected by the other.
                  </p>
                  <p className='terms-rules'>For example, a double selecting:
                  </p>
                  <p className='terms-rules'>King's Best to win the 2000 Guineas; and
                  </p>
                  <p className='terms-rules'>King's Best to win the Derby.
                  </p>
                  <p className='terms-rules'>As the odds for King's Best to win the Derby depend on whether he wins the 2000 Guineas, the odds for him to win the Derby at the time the bet was struck cannot be used to calculate the double. A special price to win both races will be quoted instead.

                  </p>
                  <p className='terms-rules'>In the case of all multiples including selections with related outcomes, if a bet is taken inadvertently Mmadness reserves the right to void part of or the whole of the bet.
                  </p>

                </div>


                <div>
                  <p><b>2-Way Markets</b></p>
                  <p className='terms-rules'>In the event of a tie in a 2-Way market, where no price is offered for the tie, bets will be settled according to the individual sports rules.
                  </p>

                </div>

                <div>
                  <p><b>Dead-Heats</b></p>
                  <p className='terms-rules'>Where selections dead-heat, the stake money is proportioned according to the number of selections which dead-heat and paid at full-odds.
                  </p>
                </div>

                <div>
                  <p><b>Any-to-come (also known as ATC or 'if cash' or 'if' bets)</b></p>
                  <p className='terms-rules'>ATC (if) bets are accepted win only on all sports. You can choose to carry forward the same stake unit or a specific part of your potential returns from one selection to the next as we proceed through your list of ATC bets. ATC bets are settled in the order that they appear on your betting slip, until all the money set aside has been used. If the money staked (or subsequently carried forward) onto the next selection is insufficient to follow your instructions, then that selection will be settled in proportion to the amount available
                  </p>
                  <p className='terms-rules'>Please note that your ATC (if) stake must not exceed the stake on your previous selection.
                  </p>
                </div>

                <div>
                  <p><b>Reverse any-to-come (also known as reverse ATC or 'reverse if cash' or 'reverse if' bets)</b></p>
                  <p className='terms-rules'>You may select any number of selections (maximum 20) in two reversed ATC (if) bets. The above ATC settlement rules apply, but the stake unit will remain the same throughout the bet. For the reverse order part of your bet, the stake from any returns from the last selection on your slip will go onto the penultimate selection etc (i.e. bets are settled in the reverse order they appear on your betting slip).
                  </p>
                  <p className='terms-rules'>For all ATC and reverse ATC bets, customers have the choice when placing their bet of either Single Action or Double Action, i.e. the customer may choose to stop the bet at a void or Non-Runner.
                  </p>
                </div>

                <div>
                  <p><b>Rule 4 general sports</b></p>
                  <p className='terms-rules'>Outright markets (defined as after the draw/official field is confirmed for a specific event), may be subject to a deduction equivalent to the Rule 4 (Deductions). The Rule 4 will be applied, according to the table of deductions (a table of deductions, and further Rule 4 rules related to Horse Racing can be found within the Horse Racing Rules section); with the exception that a Rule 4 of 5 pence will not be enforced. Effectively the Rule 4 will be applied, in the case of participant withdrawals, with odds of 9/1 or smaller. Deductions may apply for any runners scratched from the Final Field prior to race commencement. Any such deductions will apply to the total payout value on all bets placed prior to the scratching and the reframing of a new market by Mmadness (a table of deductions, related to Horse Racing can be found within the Australasian Rules section).
                  </p>
                </div>


              </div>

              <div>
                <p><b>Appendix Three</b></p>
                <p><b>Racing TV end user Terms and Conditions</b></p>

                <div>
                  <p><b>Proprietary Rights and Intellectual Property</b></p>
                  <p className='terms-rules'>The Footage is owned by Racing TV Limited (“Racing TV”). Racing TV shall remain the exclusive legal and beneficial owner of all copyright and intellectual property rights in the Footage in perpetuity. All rights not explicitly granted herein are reserved by Racing TV.
                  </p>
                </div>

                <div>
                  <p><b>Permissible Use and Restrictions</b></p>
                  <p className='terms-rules'>You may view the Footage once on a personal computer screen or mobile device for personal, private, domestic, non-commercial uses only in accordance with these terms and conditions.
                  </p>

                  <p className='terms-rules'>You shall not:
                  </p>

                  <ul className='terms-list'>
                    <li>(a) Make the Footage available to anyone else;</li>
                    <li>(b) Use or view the Footage other than as expressly permitted by these terms and conditions, or copy, relay, broadcast, or communicate the Footage to the public, nor may you include any portion of or all of the Footage in any equipment or item save as specifically permitted under these terms and conditions or develop any improvement or derivative works of any part or all of the Footage;</li>
                    <li>(c) Save, edit, copy, amend, distort, modify, distribute, reformat, disassemble, delay or repeat the Footage and/or its transmission or do anything that is contrary to any of the moral rights or other rights of the copyright owner of the Footage;
                    </li>
                    <li>(d) Remove any product identification (including but not limited to screen icons), legend or notice, or any proprietary or copyright notice from any part of the Footage;</li>
                    <li>(e) Represent or claim that you own any part or all of the Footage or otherwise do anything that is inconsistent with TBS' and/or Racing TV's rights in the Footage;</li>
                    <li>(f) Store or transmit any Footage to any publication, user group, other website, electronic bulletin board, mailing list, server or other storage device in any form; or</li>
                    <li>(g) Sell or commercially exploit any part or all of the Footage in any way.</li>
                  </ul>
                </div>

                <div>
                  <p><b>Third Party Rights</b></p>
                  <p className='terms-rules'>Racing TV and each of its group companies may enforce any provision of these terms and conditions against you as a third party contract beneficiary whether in the name of Mmadness or in its own name. The Contracts (Rights of Third Parties) Act 1999 shall apply to these terms and conditions.</p>
                </div>
              </div>


              <div>
                <a href=""><button className='contact-btn'>Can't find an answer? Contact Us</button></a>
              </div>



            </div>

          </div>

        </Container>
      </div>
      <FooterHelp />
    </div>

  )
}

export default Terms
