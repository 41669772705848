import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import betlogo from '../../image/bet365-logo.png'
import './TwoFactorAuth.css'
import { useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Axios from '../../Axiostoken'
import { useNavigate } from 'react-router-dom'
import Const from '../../Const';




const TwoFactorAuth = () => {

  const verifySecret = useRef(null)
  const navigate = useNavigate()


  const verify2FA = async () => {
    try {
      const { data } = await Axios.post(`/auth/verify2faOtp`, {
        secret: verifySecret.current.value
      }, {
        headers: {
          "Authorization": window.localStorage.getItem('Mellifluous')
        }
      })
      if (data?.success === true) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => {
        verifySecret.current.value = ""
        navigate(`${Const.weblink}/`)
      }, 2000)


    } catch ({ response }) {
      if (response?.data?.success === false) {
        toast.error("Invalid-Otp", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => {
        verifySecret.current.value = ""
      }, 2000)
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='two-factor-auth-page'>
        <Container>
          <Row>
            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            </Col>

            <Col xl={4} lg={4} md={12} sm={12} xs={12} id='mid-part-main-id'>
              <div className='inner-2fa'>
                {/* <div className='betlogo'><img src={betlogo} alt="bet-logo"/></div> */}
                <div className='google-auth'><h3>Google Authenticator</h3></div>
                <h4>Google Verification</h4>
                <p>Enter the 6 digits code</p>
                <div className='input-code'><input type="text" ref={verifySecret} /></div>
                <button onClick={verify2FA}>Submit</button>
              </div>
            </Col>

            <Col xl={4} lg={4} md={12} sm={12} xs={12}>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TwoFactorAuth
