import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import './Faq.css';
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RiAccountCircleFill} from 'react-icons/ri'
import {IoMdFootball} from 'react-icons/io'
import {Link} from 'react-router-dom';
import FooterHelp from '../FooterHelp/FooterHelp';
const Faq = () => {
  return (
    <div className='faq-page'>
      <HeaderHelp />

      <div className='whole-header'>
      <Container>
       <Row>
       <Col xl={6} lg={6} md={12} sm={12} xs={12} >
       <h5 className='help-txt'>How can we help you?</h5>
       </Col>
       <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <input type="text" className='search-input' placeholder='Search'/>
       </Col>
       </Row>

       
           
    </Container>
      </div>

      <div className='whole-faq'>
        <Container>

            <div className='customer-list'>
            <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>New Customers</h5>

            <ul className='faq-list'>
                <li>Getting Started</li>
                <li>GeoLocation Technology</li>
                <li>Sports Betting 101</li>
            </ul>
         </Col>

           <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>Responsible Gaming</h5>

            <ul className='faq-list'>
                <li>Overview</li>
                <li>Stay in Control</li>
                <li>Support and Advice</li>
            </ul>
        </Col>

        <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>Betting Information</h5>

            <ul className='faq-list'>
                <li>House Rules</li>
                <li>Sports FAQs</li>
                <li>Rules</li>
            </ul>
        </Col>

        
            </Row>
            </div>
        
            <div className='customer-list'>
            <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>Login & Registration</h5>

            <ul className='faq-list'>
                <li>Username / Password</li>
                <li>Having trouble logging in?</li>
                <li>Change Password</li>
            </ul>
        </Col>

           <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>Verification</h5>

            <ul className='faq-list'>
                <li>Verification Process</li>
                <li>Enhanced Verification</li>
                <li>GeoLocation Troubleshooting</li>
            </ul>
        </Col>

        <Col xl={4} lg={4} md={6} sm={12} xs={4} >
            <h5 className='green-txt'>Deposit & Withdraw</h5>

            <ul className='faq-list'>
                <li>Payment Methods</li>
                <li>Deposit Queries</li>
                <li>Withdrawal Queries</li>
            </ul>
        </Col>

        
            </Row>
            </div>

           
</Container>
      </div>

    <Container>
   <div className='accnt-sprt'>

  <Link to="/myaccount" className='accnt-head'><RiAccountCircleFill />My Account</Link>
   <Link to="" className='accnt-head'><IoMdFootball />Sports</Link>
   
    </div>
     </Container>
     <FooterHelp/>
    </div>
  )
}

export default Faq

