import React from 'react'
import Betslip from '../../images/Betslip.svg'
import empty from '../../images/empty.svg'

const BoxingListRightSide = () => {
  return (
    <div className='outer-right-side'>
        <div className='Betslip'>
            <img src={Betslip}/>
            <h4>Betslip</h4>
        </div>

        <div className='emptylip'>
            <img src={empty}/>
            <h4>Your betslip is empty</h4>
            <p>Click on odds to add a bet to the betslip</p>
        </div>

    </div>
  )
}

export default BoxingListRightSide
