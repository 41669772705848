import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import settingicon from '../../images/setting-icon.svg'
import boxinggloves from '../../images/boxing_gloves.svg'
import Josh from '../../images/Josh.svg'
import Lopez from '../../images/Lopez.svg'
import { Link, useNavigate } from 'react-router-dom'
import Axios from '../../Axios';
import axios from 'axios'
import Const from '../../Const';

import firstimg from '../../image/jarde.png'
import secondimg from '../../image/VETTORI_MARVIN_03-18.png'
import noimg from '../../image/empty.jpg'

const BoxingListBody = ({ status }) => {
    const [datas, setdatas] = useState([])
    const navigate = useNavigate()

    const [hover, sethover] = useState(false)

    useEffect(() => {
        console.log(status, "status")
        if (status === true) {
            fetchdata("data")
        } else {
            fetchdata("")
        }
    }, [status])

    //rapid mma api
    // const fetchdata = async (dts) => {
    //     const { data } = await Axios.get(`/auth/getTournamentList`)
    //     const dt = []
    //     for (let i = 0; i < data.result.length; i++) {
    //         if (dts === "data") {
    //             const date = new Date(data.result[i].tournaments.startTimestamp * 1000)
    //             const obj = {
    //                 away: data.result[i].tournaments?.awayTeam?.name,
    //                 home: data.result[i].tournaments?.homeTeam?.name,
    //                 awaynick: data.result[i].tournaments?.awayTeam?.playerTeamInfo?.nickname,
    //                 awaycountry: data.result[i].tournaments?.awayTeam?.country?.name,
    //                 awayrank: data.result[i].tournaments?.awayTeam?.ranking,
    //                 homenick: data.result[i].tournaments?.homeTeam?.playerTeamInfo?.nickname,
    //                 homecountry: data.result[i].tournaments?.homeTeam?.country?.name,
    //                 homerank: data.result[i].tournaments?.homeTeam?.ranking,
    //                 time: `${date.getHours()}:${date.getMinutes()}`,
    //                 month: `June  ${date?.getDate()}`,
    //                 image1: noimg,
    //                 image2: noimg,
    //             }
    //             // const options = {
    //             //     method: 'GET',
    //             //     url: 'https://mmaapi.p.rapidapi.com/api/mma/team/469994/image',
    //             //     headers: {
    //             //         'X-RapidAPI-Key': '7e7ac1246emsh377163a93ba3b00p11e76fjsna28ae2099241',
    //             //         'X-RapidAPI-Host': 'mmaapi.p.rapidapi.com'
    //             //     }
    //             // };

    //             // try {
    //             //     const response = await axios.request(options);
    //             //     console.log(response.data);
    //             //     obj.image = response.data
    //             // } catch (error) {
    //             //     console.error(error);
    //             // }
    //             dt.push(obj)
    //         } else {
    //             // if (data?.result[i]?.tournaments?.startTimestamp === 1687042800) {
    //             const date = new Date(data.result[i].tournaments.startTimestamp * 1000)
    //             const obj = {
    //                 away: data.result[i].tournaments?.awayTeam?.name,
    //                 home: data.result[i].tournaments?.homeTeam?.name,
    //                 time: `${date.getHours()}:${date.getMinutes()}`,
    //                 month: `June  ${date?.getDate()}`,
    //                 awaynick: data.result[i].tournaments?.awayTeam?.playerTeamInfo?.nickname,
    //                 awaycountry: data.result[i].tournaments?.awayTeam?.country?.name,
    //                 awayrank: data.result[i].tournaments?.awayTeam?.ranking,
    //                 homenick: data.result[i].tournaments?.homeTeam?.playerTeamInfo?.nickname,
    //                 homecountry: data.result[i].tournaments?.homeTeam?.country?.name,
    //                 homerank: data.result[i].tournaments?.homeTeam?.ranking,
    //                 image1: firstimg,
    //                 image2: secondimg
    //             }
    //             // const options = {
    //             //     method: 'GET',
    //             //     url: 'https://mmaapi.p.rapidapi.com/api/mma/team/469994/image',
    //             //     headers: {
    //             //         'X-RapidAPI-Key': '7e7ac1246emsh377163a93ba3b00p11e76fjsna28ae2099241',
    //             //         'X-RapidAPI-Host': 'mmaapi.p.rapidapi.com'
    //             //     }
    //             // };

    //             // try {
    //             //     const response = await axios.request(options);
    //             //     console.log(response.data);
    //             //     obj.image = response.data
    //             // } catch (error) {
    //             //     console.error(error);
    //             // }
    //             dt.push(obj)
    //             // }
    //         }
    //     }
    //     setdatas(dt)
    // }
    //rapid bet365api
    const fetchdata = async (dts) => {
        const { data } = await Axios.get(`/auth/getTournamentList`)
        const dt = []
        console.log(data?.result, "asdf")
        for (let i = 0; i < data?.result?.length; i++) {
            const date = new Date(data?.result[i]?.time * 1000)
            const obj = {
                away: data.result[i]?.away?.name,
                home: data.result[i]?.home?.name,
                time: `${date.getHours()}:${date.getMinutes()}`,
                month: `June  ${date?.getDate()}`,
                // awaynick: data.result.awayTeam?.playerTeamInfo?.nickname,
                // awaycountry: data.result.awayTeam?.country?.name,
                // awayrank: data.result.awayTeam?.ranking,
                // homenick: data.result.homeTeam?.playerTeamInfo?.nickname,
                // homecountry: data.result.homeTeam?.country?.name,
                // homerank: data.result.homeTeam?.ranking,
                image1: noimg,
                image2: noimg
            }
            dt.push(obj)
        }
        setdatas(dt)
    }

    useEffect(() => {
        fetchdata()
    }, [])
    return (
        <div className='center-section'>
            <Container>
                <Row>
                    {datas !== [] ?
                        <>
                            {datas?.map((item, index) => {
                                return (

                                    <Col xl={4} lg={4} md={12} sm={12} xs={12} key={index} onClick={() => { sethover(!hover) }}>
                                        <div className='score board'>
                                            <div className='team-name'>
                                                <div className='single-team'>
                                                    <img src={item?.image1} alt="Josh_Taylor" width='80px' />
                                                    <h6>{item?.away}</h6>
                                                </div>

                                                <div className='center-at'>
                                                    <h2>VS</h2>
                                                    <h4>{item?.time}</h4>
                                                </div>

                                                <div className='single-team'>
                                                    <img src={item?.image2} alt="Lopez" width='80px' />
                                                    <h6>{item?.home}</h6>
                                                </div>

                                            </div>

                                            <div className='bet-btn' onClick={() => { navigate(`${Const.weblink}/boxing-match`, { state: { data: item } }) }}>
                                                <Link className='Bet' variant="warning">Bet Now</Link>
                                            </div>

                                        </div>
                                    </Col>
                                )
                            })}
                        </>
                        : <></>
                    }

                    <div className='displayCard' style={hover !== true ? { display: "none" } : {}} >
                        <div style={{ color: "black" }}>
                            <img src={firstimg} alt='ramesh' width='80px' /><br />
                            <p>Name: </p>
                            <p>{datas[0]?.away}</p>
                            <p>Nick Name: </p>
                            <p>{datas[0]?.awaynick}</p>
                            <p>Ranking: </p>
                            <p>{datas[0]?.awayrank}</p>
                            <p>country: </p><p>{datas[0]?.awaycountry}</p>
                        </div>
                        <div style={{ color: "black" }}>
                            <img src={secondimg} alt='ramesh' width='80px' />
                            <p>Name: </p>
                            <p>{datas[0]?.home}</p>
                            <p>Nick Name: </p>
                            <p>{datas[0]?.homenick}</p>
                            <p>Ranking: </p>
                            <p>{datas[0]?.homerank}</p>
                            <p>country: </p><p>{datas[0]?.homecountry}</p>
                        </div>
                    </div>
                </Row>

            </Container>
        </div>
    )
}

export default BoxingListBody
