import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import Container from 'react-bootstrap/esm/Container.js'
import FooterHelp from '../FooterHelp/FooterHelp'
import './Privacy.css';

const Privacy = () => {
    return (
        <div className='Privacy-page'>
            <HeaderHelp />

            <div>
                <div className='home-section'>
                    <div className='top-head'>
                        <h5 className='terms-head'>Privacy Policy</h5>
                    </div>

                    <Container>
                        <div className='terms-condition'>

                            <div>
                                <p className='terms-rules'>The following Mmadness Group U.S. entities (“Mmadness”, “we,” “us” or “our”) is committed to privacy and willonly use Personal Information in accordance with the terms of this Privacy Policy. This Policy governs thecollection, use, retention and disclosure of Personal Information received from your use of our services:</p>
                            </div>

                            <div>
                                <ul className='terms-list privacy-list'>
                                    <li>Hillside (New Media Holdings US) Inc</li>
                                    <li>Hillside (US New Media) LLC</li>
                                    <li>Hillside (Fantasy) LLC</li>
                                    <li>Hillside (Technology US) LLC</li>
                                    <li>Hillside (Shared Services US) LLC</li>
                                    <li>Hillside (Arizona) LLC</li>
                                    <li>Hillside (California) LLC</li>
                                    <li>Hillside (Colorado) LLC</li>
                                    <li>Hillside (Indiana) LLC</li>
                                    <li>Hillside (Iowa) LLC</li>
                                    <li>Hillside (Louisiana) LLC</li>
                                    <li>Hillside (New Jersey) LLC</li>
                                    <li>Hillside (New York) LLC</li>
                                    <li>Hillside (Ohio) LLC</li>
                                    <li>Hillside (Texas) LLC</li>
                                    <li>Hillside (Virginia) LLC</li>
                                </ul>
                            </div>

                            <div>
                                <p className='terms-rules'>Please note, this Policy does not apply to any of our non-U.S. subsidiaries.</p>
                                <p className='terms-rules'>To the extent that we provide you with notice through our services of different or additional privacypolicies or practices (e.g. at the point of collection), those additional terms shall govern such datacollection and use.</p>
                                <p className='terms-rules'>We will process any personal information provided to us or otherwise held by us relating to you in the
                                    manner set out in this Privacy Policy.</p>
                            </div>

                            <div>
                                <p><b>TYPES OF PERSONAL DATA COLLECTED</b></p>
                                <p className='terms-rules'>Personal Information is gathered in two ways:</p>
                                <p className='terms-rules'>1. From your interaction with our website and services, i.e., certain Website information about you, such as domain name, IP address, browser type and page views, mobile device ID and type, and geolocation which is collected through our Website's or our Apps’ technology to provide certain functionality to you, to remember you when you return, to customize our Website to your preferences and manage content, and compile statistics about Website usage; and</p>
                                <p className='terms-rules'>2. Directly from you, when a. you voluntarily submit Personal Information such as, first and last names, addresses, email
                                    address(es), phone numbers, birthdate, preference information, and b. when you register to become a user of the Website or the Apps we will collect the password and related
                                    security information.</p>
                                <p className='terms-rules'>When we collect this type of information we will notify you as to why we are asking for information and
                                    how this information will be used either in this Policy or separately at the point at which the information is collected if not described in this Policy.</p>
                            </div>


                            <div>
                                <p><b>RIGHT NOT TO PROVIDE INFORMATION</b></p>
                                <p className='terms-rules'>In certain jurisdictions, you may have the right not to provide Personal Information. However, if you elect
                                    not to provide such information, you may not be able to utilize certain services.</p>
                            </div>

                            <div>
                                <p><b>GEOLOCATION</b></p>
                                <p className='terms-rules'>In order to provide services to you Mmadness is required to establish your physical location from technologies
                                    like GPS, Wi-Fi, or cell tower proximity. Without this information we will be unable to provide services to you.</p>
                            </div>

                            <div>
                                <p><b>COOKIES/ONLINE TRACKING</b></p>
                                <p className='terms-rules'>Some information that we collect about you is collected passively through the use of "cookies". Cookies
                                    are small files of information, which save and retrieve information about your visit to the Website - for example, how you entered and navigated our Website, and what information was of interest to you. We use this information to remember you when you return and to customize our Website to your preferences.
                                </p>
                            </div>

                            <div>
                                <p><b>DO NOT TRACK SIGNALS</b></p>
                                <p className='terms-rules'>Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in the Cookies section (e.g. by disabling cookies).
                                </p>
                            </div>

                            <div>
                                <p><b>PURPOSES FOR PERSONAL DATA COLLECTION AND USE</b></p>
                                <p className='terms-rules'>We collect and use such personal data to provide and improve our services, customer and technical
                                    support, and billing and marketing activities, to respond to requests, to process transactions, for administrative purposes, to fully comply with our legal and regulatory obligations and as otherwise
                                    instructed by customers and their authorized users.
                                </p>

                                <p className='terms-rules'>Pursuant to applicable law, all player information will be securely erased from hard disks, magnetic tapes, solid state memory, and other devices, as applicable, before the device is properly disposed of by us. If
                                    erasure is not possible, the storage device will be destroyed.
                                </p>
                            </div>

                            <div>
                                <p><b>DISCLOSURES TO THIRD PARTIES</b></p>
                                <p className='terms-rules'>Mmadness does not sell your personal data for marketing, or any other, purposes. No such sales have been made in the preceding 12 months.
                                    made in the preceding 12 months.
                                </p>

                                <p className='terms-rules'>No mobile information will be shared with third parties or affiliates for marketing or promotional purposes,
                                    any previously granted consent or opt in status will not be shared with any affiliates or third parties.
                                </p>
                                <p className='terms-rules'>All personal data collected will be kept confidential, however we may be required to disclose personal data
                                    in response to lawful requests by the relevant public and regulatory authorities, including to meet national security or law enforcement requirements.
                                </p>
                                <p className='terms-rules'>If we transfer your Personal Data to a third party, we will ensure the third party is contractually obligated
                                    to process your data only for limited, specific purposes consistent with this policy, to apply the same level of protection to that data as Mmadness.
                                </p>

                                <p className='terms-rules'>As part of our service we may transfer data to other companies within the Mmadness group, where this may
                                    occur we are committed to do so under standard contractual clauses or binding corporate rules or any other acceptable method that ensures a protection of your personal information to the standard required
                                    within the European Economic Area (EEA). Upon notice we will take reasonable and appropriate steps to stop and remediate unauthorized processing.
                                </p>

                                <p className='terms-rules'>We remain responsible for the personal data that we share with third parties for processing on our behalf, and we remain liable under the Principles if such third parties process such personal data in a manner
                                    inconsistent with the Principles and we are responsible for the event giving rise to the damage.
                                </p>

                            </div>

                            <div>
                                <p><b>DISCLOSURES OF PERSONAL DATA FOR A BUSINESS PURPOSE</b></p>
                                <p className='terms-rules'>In the preceding 12 months we have disclosed the following categories of personal information for a
                                    business purpose to the parties identified above:
                                </p>

                            </div>

                            <div>
                                <p><b>RIGHTS OF ACCESS AND LIMITING USE AND DISCLOSURE</b></p>
                                <p className='terms-rules'>When required in your jurisdiction we will permit individuals to access, correct, amend or delete Personal
                                    Information that is demonstrated to be inaccurate or incomplete.
                                </p>

                                <p className='terms-rules'>To exercise these rights, please contact us by emailing <a href="" className='link-tag'>data.protection-usa@Mmadness.com</a> or by contacting
                                    Customer Services on the Toll Free number 1-888-8-bet-365. Please note that should we, in any situation, be acting as a processor of personal data for our customer, we may first refer your request to the
                                    customer that submitted your personal data, and we will assist our customer as needed in responding to your request.
                                </p>

                                <p className='terms-rules'>Reasonable access to Personal Information will generally be provided within forty-five (45) calendar days
                                    at no cost to you, subject to limited exceptions prescribed by law.
                                </p>

                                <p className='terms-rules'>Mmadness has further committed to cooperate with the panel established by the EU data protection authorities (DPAs) with regard to unresolved complaints concerning human resources data transferred
                                    authorities (DPAs) with regard to unresolved complaints concerning human resources data transferred from the EU in the context of the employment relationship.
                                </p>

                                <p className='terms-rules'>If the issue cannot be resolved through our internal processes, you may file a claim free of charge with the Maltese Information and Data Protection Commissioner, an independent EU dispute resolution provider, at
                                    <a href="" className='link-tag'> https://idpc.org.mt/.</a>
                                </p>

                                <p className='terms-rules'>If you have a complaint left unresolved by all available recourse mechanisms, you may invoke binding arbitration.
                                </p>

                            </div>

                            <div>
                                <p><b>ENFORCEMENT</b></p>
                                <p className='terms-rules'>Our commitments are subject to the investigatory and enforcement powers of the U.S. Federal Trade Commission.
                                </p>

                            </div>

                            <div>
                                <p><b>YOUR CALIFORNIA PRIVACY RIGHTS / NOTICE FOR CALIFORNIA RESIDENTS</b></p>
                                <p className='terms-rules'>California residents ("CA customers") have certain rights concerning their personal information. CA residents who have provided Personal Information to us may obtain information or exercise other
                                    rights, regarding our processing and disclosures, if any, of Personal Information to third parties for third-party direct marketing purposes.
                                </p>

                            </div>

                            <div>
                                <p><b>MAKING A REQUEST</b></p>
                                <p className='terms-rules'>Requests for any of these services may be made to the following email address: data.protection-
                                    <a href="" className='link-tag'> usa@Mmadness.com</a>  or through contacting Customer Services on the Toll Free number 1-888-8-bet-365.
                                </p>
                                <p className='terms-rules'><p className='terms-rules'>All requests to access any rights must be verifiable. To be verifiable your request must:</p></p>
                                <p className='terms-rules'>• Provide sufficient information that allows us to reasonably verify you are the person about whom we
                                    collected personal information or an authorized representative.</p>
                                <p className='terms-rules'>• Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond</p>
                                <p className='terms-rules'>We cannot respond to your request or provide you with personal information if we cannot verify your
                                    identity or authority to make the request and confirm the personal information relates to you.
                                </p>

                                <p className='terms-rules'>Any disclosures we provide will only cover the 12-month period preceding the receipt of a verifiable request. The response we provide will also explain the reasons we cannot comply with a request, if
                                    applicable.
                                </p>
                            </div>

                            <div>
                                <p><b>RIGHT OF ACCESS AND PORTABILITY</b></p>
                                <p className='terms-rules'>You have the right to request that we disclose certain information to you about our collection and use of
                                    your personal information over the past 12 months.
                                </p>

                                <p className='terms-rules'>You may only make a verifiable consumer request for access or data portability twice within a 12-month period.
                                </p>

                            </div>

                            <div>
                                <p><b>RIGHT OF DELETION</b></p>
                                <p className='terms-rules'>California residents ("CA customers") have certain rights concerning their personal information.
                                </p>

                                <p className='terms-rules'>Under the California Consumer Privacy Act of 2018 (“CCPA”), you have the right to request that we delete
                                    any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request we will delete (and direct our service
                                    providers to delete) your personal information from our records, unless an exception applies.
                                </p>

                            </div>

                            <div>
                                <p><b>RESPONSE TIMES</b></p>
                                <p className='terms-rules'>Within 45 days of receiving such a request, we will provide a California Privacy Disclosure.
                                </p>
                            </div>

                            <div>
                                <p><b>NON-DISCRIMINATION</b></p>
                                <p className='terms-rules'>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
                                </p>
                                <ul className='privacy-lists'>
                                    <li>Deny you goods or services.</li>
                                    <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                                    <li>Provide you a different level or quality of goods or services.</li>
                                    <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>

                                </ul>
                            </div>

                            <div>
                                <p><b>OTHER CALIFORNIA PRIVACY RIGHTS</b></p>
                                <p className='terms-rules'>California’s “Shine the Light” law (Civil Code Section § 1798.83) permits CA customers to request certain information regarding any disclosure of personal information to third parties to enable their direct
                                    marketing purposes.
                                </p>
                                <p className='terms-rules'>We do not sell, or otherwise disclose, your data for this purpose.</p>

                            </div>

                            <div>
                                <p><b>CHANGES TO OUR PRIVACY STATEMENT</b></p>
                                <p className='terms-rules'>We may update this policy from time to time, so please review it frequently, any changes to this Policy are in force at the time of posting.
                                </p>
                                <p className='terms-rules'>If we make any changes to our Policy, we will post the updated Policy to this Website, and may post it to
                                    other places we deem appropriate. Users who have authorized email communication will be notified via email of any material changes to the Policy. We will use Personal Information in accordance with the Policy
                                    under which it was collected.</p>

                            </div>

                            <div>
                                <p><b>CONTACTING US</b></p>
                                <p className='terms-rules'>If you have any concerns, or would like more detail about how we process your Personal Data, you can
                                    contact us using <a href="" className='link-tag'> data.protection-usa@Mmadness.com.</a>
                                </p>
                            </div>


                            <div>
                                <a href=""><button className='contact-btn'>Can't find an answer? Contact Us</button></a>
                            </div>






                        </div>
                    </Container>
                    <FooterHelp />

                </div>
            </div>

        </div>
    )
}

export default Privacy
