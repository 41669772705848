import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import Container from 'react-bootstrap/esm/Container.js'
import FooterHelp from '../FooterHelp/FooterHelp'
import './Protection.css'

const Protection = () => {
  return (
    <div className='protection-page'>
      <HeaderHelp />
      <div className='home-section'>
        <div className='top-head'>
          <h5 className='terms-head'>Player Protection</h5>
        </div>

        <Container>
          <div className='terms-condition'>

            <div className='terms-full-sctn'>
              <div>
                <p><b>Age Restrictions</b></p>
                <p className='terms-rules'>You must be 21 years of age or older to be able to register and participate in online gambling.</p>
                <p className='terms-rules'>It is a criminal offense to gamble under the age of 21 and anyone who facilitates gambling for an
                  individual under the age of 21 has committed a criminal offense and will be prohibited from participating
                  in online gambling.</p>

              </div>

              <div>
                <p><b>Location - Federal Prohibitions and Restrictions</b></p>
                <p className='terms-rules'>There are Federal prohibitions and restrictions regarding Internet gaming, specifically, any limitations upon
                  Internet gaming as set forth in 18 U.S.C. §§ 1084 et seq. (The Wire Act) and 31 U.S.C. §§ 3163 through
                  3167 (UIEGA).</p>

                <p className='terms-rules'>There are Federal prohibitions and restrictions regarding Internet gaming, specifically, any limitations upon
                  Internet gaming as set forth in 18 U.S.C. §§ 1084 et seq. (The Wire Act) and 31 U.S.C. §§ 3163 through
                  3167 (UIEGA).</p>
                <p className='terms-rules'>You will be required to use <a href="" className='link-tag'>geo-location technolog</a>y to verify your location in order to gamble with Mmadness.</p>

              </div>

              <div>
                <p><b>Account Security</b></p>
                <p className='terms-rules'>When you open your account, you will select your own Username and Password. You must keep your
                  Username and Password confidential, as you are responsible for all activity and bets placed on your
                  account.</p>

                <p className='terms-rules'>You can change your Password, when logged in, by visiting the Account Menu You can change your <a href="" className='link-tag'>Password</a>, when logged in, by visiting the Account Menu </p>

                <p className='terms-rules'>You can enable or disable Strong Authentication via My Account in the Account Menu onscreen instructions. In order to enable and use this security feature you will need to be able to receive
                  SMS messages to the phone number registered on your account.</p>

                <p className='terms-rules'>If your device has Touch ID or Face ID Login capability you can enable this to log in to the Mmadness app
                  quickly and securely. You can enable or disable these features via My Account in the Account Menu </p>

              </div>

              <div>
                <p><b>Third Party Access</b></p>
                <p className='terms-rules'>You may open only one account, and you must register using your own name. You may only access
                  Mmadness from your own account and you must not allow any other person to access or use your account.</p>

                <p className='terms-rules'>It is your responsibility to configure your client device's auto-lock feature to protect your client device
                  from unauthorized use.</p>

                <p className='terms-rules'>If you believe that a third party knows your account details or is using your account, please Contact Us
                  immediately and change your Password.</p>

              </div>


              <div>
                <p><b>Account Activity</b></p>
                <p className='terms-rules'>You can access your account activity by selecting History within the Members section.</p>

                <p className='terms-rules'>Unsettled Sports bets and Sports bets settled within the last 24 hours can be viewed in the My Bets
                  section which can be accessed from the top of the page.</p>

                <p className='terms-rules'>Obtaining <a href="" className='link-tag'>Terms and Conditions</a>
                  Our most recent Terms and Conditions are always available at the bottom of the Mmadness Home page.</p>

                <p className='terms-rules'>If you wish to obtain a copy of the Terms and Conditions that you accepted when registering your account,
                  please <a href="" className='link-tag'>Contact Us</a>.</p>

              </div>

              <div>
                <p><b>Making a Complaint</b></p>
                <p className='terms-rules'>
                  Should you wish to raise a complaint with Mmadness, then please Contact Us. Your complaint will be assigned
                  to an individual member of our Customer Service team to assess. We will endeavour to respond within 48
                  hours.</p>

                <p className='terms-rules'>If you are not satisfied with our response, you can request an escalation which will be overseen by a
                  member of our Customer Service Management team. This should be viewed as Mmadness’s final response
                  and you will be issued with a Complaint Reference.</p>

                <p className='terms-rules'>If you are not in agreement with our final response you can raise your complaint directly to the Colorado
                  Division of Gaming(CODOG). To do this you will need to follow all steps detailed and provide the
                  information listed on their website.</p>

                <p className='terms-rules'>Disputes are referred to CODOG by selecting Sports Betting Patron Complaint under Patron Complaints on
                  CODOG’s <a href="" className='link-tag'>Contact Us</a> page.</p>

              </div>

              <div>
                <a href=""><button className='contact-btn'>Can't find an answer? Contact Us</button></a>
              </div>
            </div>
          </div>

        </Container>
        <FooterHelp />
      </div>
    </div>
  )
}

export default Protection
