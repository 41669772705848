import React, { useState, useRef } from 'react'
import './Register.css'
import HeaderMain from "../HeaderMain/HeaderMain";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'
import InputGroup from 'react-bootstrap/InputGroup';

import Axios from '../../Axios'
import Const from '../../Const';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Register = ({ setIsDrawerOpen, isDrawerOpen }) => {

  const navigate = useNavigate()

  const [country, setcountry] = useState("India")
  const [title, settitle] = useState("Mr")
  const name = useRef(null)
  const recname = useRef(null)
  const email = useRef(null)
  const password = useRef(null)
  const [date, setdate] = useState("")
  const [month, setmonth] = useState("")
  const [year, setyear] = useState("")
  const [checked, setchecked] = useState(false)

  const [nameerr, setnameerr] = useState(null)
  const [recnameerr, setrecnameerr] = useState(null)
  const [emailerr, setemailerr] = useState(null)

  const [dateerr, setdateerr] = useState("")
  const [checkederr, setcheckederr] = useState(null)
  const [passerr, setpasserr] = useState(null)

  const [visibile, setvisible] = useState(false)

  const [countryList, setcountryList] =
    useState(["Albania",
      "Algeria",
      "Andorra",
      "Anguilla",
      "Antigua And Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belize",
      "Benin",
      "Bermuda",
      "Bolivia",
      "Bosnia-Herzegovina",
      "Botswana",
      "Brazil",
      "British Virgin Islands",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Cameroon",
      "Canada",
      "Cape Verde Islands",
      "Cayman Islands",
      "Central African Republic",
      "Chile",
      "China",
      "Cook Islands",
      "Costa Rica",
      "Croatia",
      "Cyprus",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Estonia",
      "Ethiopia",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "French Polynesia",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guyana",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Ireland (Rep. Of)",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Korea (South)",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Moldova",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Namibia",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Qatar",
      "Rwanda",
      "San Marino",
      "Sao Tome E Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Spain",
      "Sri Lanka",
      "St. Kitts And Nevis",
      "St. Lucia",
      "St. Vincent And The Grenadines",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Taiwan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad And Tobago",
      "Tunisia",
      "Turks And Caicos Islands",
      "Uganda",
      "United Arab Emirates",
      "United States",
      "United Kingdom",
      "Uruguay",
      "Vanuatu",
      "Vietnam",
      "Western Samoa",
      "Zambia",])


  const setCountry = (e) => {
    setcountry(e.target.value)
  }
  const setTitle = (e) => {
    settitle(e.target.value)
  }
  const setBirthday = (type, data) => {
    if (type === "date") {
      setdate(data.value)
    } else if (type === "month") {
      setmonth(data.value)
    } else if (type === "year") {
      setyear(data.value)
    }
  }
  const setCheckout = (e) => {
    setchecked(e.target.checked)
  }

  const checkemail = (data) => {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
    if (data.value !== "") {
      if (!re.test(data.value)) {
        setemailerr("Please Enter a Valid Email")
      }
    }
  }

  const onSubmit = async () => {
    try {
      if (name.current.value === "") {
        setnameerr("Please Enter a Name")
      } else if (recname.current.value === "") {
        setrecnameerr("Please Enter a Recipient Name")
      } else if (date === "" || month === "" || year === "") {
        setdateerr("Please Select a Date")
      } else if (email.current.value === "") {
        setemailerr("Please Enter a Email")
      } else if (password.current.value === "") {
        setpasserr("Please Enter a Password")
      } else if (checked === false) {
        setcheckederr("Please tick the CheckBox")
      } else {
        const dob = `${date}/${month}/${year}`
        const { data } = await Axios.post(`/users/register`, {
          name: name.current.value,
          user_name: recname.current.value,
          email: email.current.value,
          surname: title,
          dob: dob,
          country: country,
          password: password.current.value
        })
        if (data?.success) {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate(`${Const.weblink}/emailotp`, { state: { email: email.current.value } })
            // navigate(`${Const.weblink}/`)
          }, 4000)
        }
      }
    } catch ({ response }) {
      if (response?.data?.message === "Email Already Exists") {
        setemailerr(response?.data?.message)
      } else {
        setpasserr(response?.data?.message)
      }
    }

  }


  return (
    <div className="register-page-main">
      <HeaderMain setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} />
      <ToastContainer />
      <div className='register-section'>
        <Container>
          <div className='register-title'>
            <h1>Open Account</h1>
            <p>Get Help with this form — <span>Contact Us</span></p>
            <p>Country of residence  <span style={{ color: "red" }} >*</span></p>
          </div>
          <select id="country_residence" class="oam-FieldCountrySelect_Select " onChange={setCountry}>
            {countryList.map((item, index) => {
              if (item === "India") {
                return (<option value={item} selected class="oam-FieldCountrySelect_Select" > {item}</option>)
              } else {
                return (<option value={item} class="oam-FieldCountrySelect_Select" > {item}</option>)
              }

            })}
          </select>

          <div className='head-title'>
            <h2>Personal information  <span style={{ color: "red" }} >*</span></h2>
            <p>Please enter accurate information. Your identity must be verified to allow continued use of your account.</p>
          </div>

          <div class="fml-FieldSelect ">
            <label for="title" class="fml-FieldSelect_Label ">Title  <span style={{ color: "red" }} >*</span></label>
            <div class="fml-FieldSelect_InputRow ">
              <select id="title" class="fml-FieldSelect_Select " onChange={setTitle}>
                <option value="Mr" class="fml-FieldSelect_Option ">Mr</option>
                <option value="Mrs" class="fml-FieldSelect_Option ">Mrs</option>
                <option value="Ms" class="fml-FieldSelect_Option ">Ms</option>
                <option value="Miss" class="fml-FieldSelect_Option ">Miss</option>
              </select>
            </div>
          </div>

          <div className='input-text'>
            <InputGroup>
              <Form.Control
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                ref={name}
                onChange={() => { setnameerr("") }}
              />
            </InputGroup>
            {nameerr !== "" ? <div style={{ textAlign: "center", color: "red" }} >{nameerr}</div> : <></>}
            <InputGroup >
              <Form.Control
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                ref={recname}
                onChange={() => { setrecnameerr("") }}
              />
            </InputGroup>
            {recnameerr !== "" ? <div style={{ textAlign: "center", color: "red" }} >{recnameerr}</div> : <></>}
          </div>

          <p className='date0f'>Date Of birth  <span style={{ color: "red" }} >*</span></p>

          <div className='dob'>

            <select id="title" class="fml-FieldSelect_Select " onChange={({ target }) => { setBirthday("date", target); setdateerr("") }}>
              <option value="">Day</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
            </select>

            <select id="title" class="fml-FieldSelect_Select " onChange={({ target }) => { setBirthday("month", target); setdateerr("") }}>
              <option value="">Month</option>
              <option value="01">Jan</option>
              <option value="02">Feb</option>
              <option value="03">Mar</option>
              <option value="04">Apr</option>
              <option value="05">May</option>
              <option value="06">Jun</option>
              <option value="07">Jul</option>
              <option value="08">Aug</option>
              <option value="09">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </select>

            <select id="title" class="fml-FieldSelect_Select " onChange={({ target }) => { setBirthday("year", target); setdateerr("") }}>
              <option value="" >Year</option>
              <option value="2005" >2005</option>
              <option value="2004" >2004</option>
              <option value="2003" >2003</option>
              <option value="2002" >2002</option>
              <option value="2001" >2001</option>
              <option value="2000" >2000</option>
              <option value="1999" >1999</option>
              <option value="1998" >1998</option>
              <option value="1997" >1997</option>
              <option value="1996" >1996</option>
              <option value="1995" >1995</option>
              <option value="1994" >1994</option>
              <option value="1993" >1993</option>
              <option value="1992" >1992</option>
              <option value="1991" >1991</option>
              <option value="1990" >1990</option>
            </select>
          </div>
          {dateerr !== "" ? <div style={{ textAlign: "center", color: "red" }} >{dateerr}</div> : <></>}
          <div className='head-title'>
            <h2>Contact information <span style={{ color: "red" }} >*</span></h2>
            <InputGroup >
              <Form.Control
                placeholder="Email address"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                ref={email}
                onChange={({ target }) => { setemailerr(null); checkemail(target) }}
              />
            </InputGroup>
            {emailerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{emailerr}</div> : <></>}
          </div>
          <div className='head-title'>
            <h2>Password <span style={{ color: "red" }} >*</span></h2>
            <InputGroup >
              <Form.Control
                type={visibile ? "text" : "password"}
                placeholder="Password"
                aria-label="Password"
                aria-describedby="basic-addon2"
                ref={password}
                onChange={() => { setpasserr(null) }}
              />
              {/* <span className='visibilityicon' onClick={() => { setvisible(!visibile) }}> {visibile ? <Visibility style={{ marginTop: "50%" }} /> : <VisibilityOff style={{ marginTop: "50%" }} />}  </span> */}

            </InputGroup>


          </div>
          <div className='term1'>
            <input type='checkbox' onClick={() => { setvisible(!visibile) }} />
            <span style={{ marginLeft: "5px" }} >Show Password</span>
          </div>
          {passerr !== null ? <div style={{ textAlign: "center", color: "red" }} >{passerr}</div> : <></>}
          <div className='term'>
            <InputGroup>
              <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => { setCheckout(e); setcheckederr(null) }} />
            </InputGroup>
            <p>I am at least 18 years of age and I have read, accept and agree to the <span>Terms and Conditions, </span><span>Rules, </span><span>Privacy Policy,     </span>and policies relating to <span>age</span>and<span>identity verification</span></p>
          </div>
          {checkederr !== null ? <div style={{ textAlign: "center", color: "red" }} >{checkederr}</div> : <></>}
          <div style={{ backgroundColor: "#c72e2c" }} > <Button onClick={onSubmit}  >Join Mmadness</Button>{' '}</div>
        </Container>
      </div>
    </div >
  )
}

export default Register

