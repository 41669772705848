import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Link} from 'react-router-dom'
import Container from "react-bootstrap/esm/Container";
import './Header.css';
import logoimg from '../../img/madnesssbet-logo.png'
import {RiSearchLine} from 'react-icons/ri'
import {HiHome} from 'react-icons/hi'
import {RiAccountPinCircleLine} from 'react-icons/ri'
import {AiOutlineSetting} from 'react-icons/ai'
import {AiOutlineMenu} from 'react-icons/ai'
import {BiHelpCircle} from 'react-icons/bi'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {BiBookAdd} from 'react-icons/bi'

import Accordion from "react-bootstrap/Accordion";

  function Example() {
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    
  
    return (
      <>
      <div className='whole-header'>
      <Container>
        <div className='whole-head'>
          <div className='home-head'>
          <Button onClick={handleShow}>
          <AiOutlineMenu/> Help
        </Button>
        </div>
        <div className='bet-logo'>
        <Link to='/mmadness'><img src={logoimg} alt="Footer-image" className='customer-img' /></Link>
        </div>
        <div>
        <RiSearchLine />
        </div> 
        </div> 

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>

           <div className='offcanvas-list-outer'>
           <ul className='offcanvas-list'>
            <li><Link to='/mmadness'><HiHome />Home</Link></li>
            </ul>
            <Accordion defaultActiveKey="0">
                  
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="accordion-head"><RiAccountPinCircleLine />My Account</Accordion.Header>
                      <Accordion.Body>
                        
                      <ul>
                      <li><Link to='/mmadness/myaccount'>Login & Registration</Link></li>
                      <li><Link to='/mmadness/deposit'>Deposits</Link></li>
                      <li>Verfication</li>
                      <li>Payment Methods</li>
                      <li>Payment Support</li>
                      <li><Link to='/mmadness/deposit'>Withdrawals</Link></li>
                      <li>Complaints Procedure</li>
                      </ul>

                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header className="accordion-head"><BiHelpCircle />Product Help</Accordion.Header>
                      <Accordion.Body>
                       <ul><li>Product Help</li></ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="accordion-head"><AiOutlineSetting />Technical Support</Accordion.Header>
                      <Accordion.Body>
                       <ul><li>Technical Support</li></ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordion-head"><FaMapMarkerAlt />GeoLocation Help</Accordion.Header>
                      <Accordion.Body>
                       <ul><li>GeoLocation Help</li></ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordion-head"><BiBookAdd />New Customers</Accordion.Header>
                      <Accordion.Body>
                       <ul><li>New Customers</li></ul>
                      </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                </div> 

          </Offcanvas.Body>
        </Offcanvas>      

      </Container>
      </div>

      </>

    );
  }
  
  export default Example;





                 