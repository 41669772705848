import React, { useEffect } from 'react'
import HeaderMain from '../HeaderMain/HeaderMain'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SmallSliderLeft from './SmallSliderLeft';
import SmallSliderRight from './SmallSliderRight';
import FooterMain from '../FooterMain/FooterMain';
import BannerMain from './BannerMain';
import { useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {

  const location = useLocation()

  const checkVerify = () => {
    const data = location?.state?.status
    if (location?.state?.page === "email") {
      if (data === 'Verified') {
        toast.success('Email Verified Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Link Expired", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  useEffect(() => {
    checkVerify()
  }, [])
  return (
    <div className='homepage-main-page'>
      <ToastContainer />
      <HeaderMain />

      <BannerMain />

      <div className='announcement-outer'>
        <Container>
          <Row>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="announcement-img"></div>
              <div className='announcement'>
                <marquee direction="left">
                  <p>Dear customer, if you have problem visiting please use jeetbuzz.net, this is our backup website link. </p>
                </marquee>
              </div>
            </Col>

          </Row>
        </Container>
      </div>


      <div className='small-slider'>
        <Container>
          <Row>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <SmallSliderLeft />
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <SmallSliderRight />
            </Col>

          </Row>
        </Container>
      </div>

      <FooterMain />

    </div>
  )
}

export default Home
