import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import Container from 'react-bootstrap/esm/Container'
import './Technical.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FooterHelp from '../FooterHelp/FooterHelp'

const Technical = () => {
  return (
    <div className='technical'>
      <HeaderHelp />
      <section className="help-section tech">
        <div className="top-head">
          <p className="terms-rules sports">Technical Support</p>
        </div>

        <div className="tab-width">
          <Tabs defaultActiveKey="first">
            <Tab eventKey="first" title="FAQs" className="t-contain">

              <Container>


                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion-head">Geo-Location Technology</Accordion.Header>
                    <Accordion.Body>
                      <p>In accordance with our regulatory requirements we will need to confirm that you are located within
                        Colorado. We do this using Geo-Location technology.</p>
                      <p>If you are using our mobile or tablet app, this software is already built in.</p>
                      <p>For mobile site users, you will need to download a geo-location app GeoGuard in order to have full access
                        to your account.</p>
                      <p>You can download the required app using the following links:</p>
                      <div className='store-link'>
                        <Link to="" >App Store</Link>
                        <Link to="" >Google play Store</Link>
                      </div>

                      <p>You will need to have notifications, WiFi and location services on your device enabled in order for the
                        software to confirm your location.</p>
                      <p>For desktop users, you will need to install a geolocation plugin in order to have full access to your account.</p>
                      <p>You can install the required software using the following links:</p>
                      <div className='store-link'>
                        <Link to="" >Windows</Link>
                        <Link to="" >Mac</Link>
                      </div>
                      <p>You will need to be connected to WiFi in order to confirm your location.</p>
                      <p>Until you have confirmed your location, you will be unable to place Sports bets.</p>
                      <p>If you have problems downloading the required software or confirming your current location, please
                        <Link to=""> Contact Us.</Link></p>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordion-head">Waiting to get onto the Mmadness app and website</Accordion.Header>
                    <Accordion.Body>
                      <p>There may be times that, due to extremely high volumes of traffic, we limit the amount of users able to use our app or website at one time.</p>
                      <p>When this happens you will be placed in a virtual queue and given an estimated wait time.</p>
                      <p>Please note, you may be directed to a virtual queue page provided by Cloudflare.</p>
                      <p>The page will automatically refresh, so do not close or refresh the app or browser. Once the app or website is available you will automatically be given access.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordion-head">Technical Support</Accordion.Header>
                    <Accordion.Body>
                      <p>Due to the nature of how our products work, there are a number of factors that may cause you to experience technical issues when accessing/navigating areas of the site or with the site’s general functionality.</p>
                      <p>Possible issues could be as follows:</p>
                      <ul className='terms-list'>
                        <li>Display and functionality issues</li>
                        <li>Problems Depositing/Withdrawing fund</li>
                        <li>Problems logging in</li>
                        <li>Unexpected/unusual error messages</li>
                        <li>Unresponsive links</li>
                      </ul>
                      <p>Should you experience any of the above, we have a 24hr dedicated Customer Technical Support team who will be happy to troubleshoot any issues with you. Please <Link to="">Contact Us</Link> if you require assistance.</p>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordion-head">Adding Mmadness to my Safe Sender list</Accordion.Header>
                    <Accordion.Body>
                      <p>From time to time we may need to notify you of important information. Therefore, we recommend that you add Mmadness to your Safe Sender list, or Contacts.</p>
                      <p>These are the email addresses we send from:</p>
                      <p>We may also send you emails about our range of offers and promotions. If you prefer not to receive these emails you can follow the unsubscribe link on any of our promotional emails.</p>
                      <p>Here is how you can add the above email addresses to your Safe Sender list with some of the most popular email providers.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>




                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
              </Container>
            </Tab>

            <Tab eventKey="two" title="System Requirements">

              <Container>

                <div className='require-list'>
                  <Link to="" className='system-require'>Sports</Link>
                  <Link to="" className='system-require'>Mobile</Link>
                  <Link to="" className='system-require'>Geo-Location Technology</Link>
                  <div>
                    <a href=""><button className='contact-btn'>Can't find an answer? Contact Us</button></a>
                  </div>
                </div>



              </Container>

            </Tab>



          </Tabs>
        </div>
        <FooterHelp />
      </section>
    </div>
  )
}

export default Technical
