import React from "react";
import HeaderHelp from '../HeaderHelp/Header'
import Footer from "../FooterHelp/FooterHelp";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import Container from 'react-bootstrap/esm/Container.js';
import "./SportsRule.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import placeBet from '../../img/place_bet.svg';
import placebet2 from '../../img/place_bet2.svg';
import placebet3 from '../../img/place_bet3.svg';
import placebet4 from '../../img/place_bet4.svg';
import placebet5 from '../../img/place_bet5.svg';
import placebet6 from '../../img/place_bet6.svg';


const SportsRule = () => {


  return (
    <div className="sportsrule-page">
      <HeaderHelp />
      <section className="help-section">
        <div className="top-head">
          <p className="terms-rules sports">Sports</p>
        </div>
       
        <div className="tab-width">
          <Tabs defaultActiveKey="first">
            <Tab eventKey="first" title="Betting Features" className="t-contain">
              
                <Container>
                <div className='country-drop'>
                <h5>Cash Out</h5> 
                </div>

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="accordion-head">Cash Out</Accordion.Header>
                      <Accordion.Body>
                       <p>Cash Out, when available, gives you more control over your bets and offers you an opportunity to take a
                        return before an event has finished. When this feature is available you can Cash Out a bet in its entirety or
                        you can partially Cash Out your bet, whilst leaving the remainder of your wager to run.</p> 
                        <p>You can also create a request for your bet to automatically Cash Out <a href="">(Auto Cash Out)</a> where the Cash Out
                        amount offered reaches a chosen value.</p>
                        <p>For further details and full Terms and Conditions, refer to our <a href="">Cash Out</a> page.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="accordion-head">Auto Cash Out</Accordion.Header>
                      <Accordion.Body>
                       <p>Cash Out, when available, gives you more control over your bets and offers you an opportunity to take a
                        return before an event has finished. When this feature is available you can Cash Out a bet in its entirety or
                        you can partially Cash Out your bet, whilst leaving the remainder of your wager to run.</p> 
                        <p>You can also create a request for your bet to automatically Cash Out <a href="">(Auto Cash Out)</a> where the Cash Out
                        amount offered reaches a chosen value.</p>
                        <p>For further details and full Terms and Conditions, refer to our <a href="">Cash Out</a> page.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="accordion-head">Partial Cash Out</Accordion.Header>
                      <Accordion.Body>
                       <p>Cash Out, when available, gives you more control over your bets and offers you an opportunity to take a
                        return before an event has finished. When this feature is available you can Cash Out a bet in its entirety or
                        you can partially Cash Out your bet, whilst leaving the remainder of your wager to run.</p> 
                        <p>You can also create a request for your bet to automatically Cash Out <a href="">(Auto Cash Out)</a> where the Cash Out
                        amount offered reaches a chosen value.</p>
                        <p>For further details and full Terms and Conditions, refer to our <a href="">Cash Out</a> page.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header className="accordion-head">Unable to Cash Out</Accordion.Header>
                      <Accordion.Body>
                       <p>Cash Out, when available, gives you more control over your bets and offers you an opportunity to take a
                        return before an event has finished. When this feature is available you can Cash Out a bet in its entirety or
                        you can partially Cash Out your bet, whilst leaving the remainder of your wager to run.</p> 
                        <p>You can also create a request for your bet to automatically Cash Out <a href="">(Auto Cash Out)</a> where the Cash Out
                        amount offered reaches a chosen value.</p>
                        <p>For further details and full Terms and Conditions, refer to our <a href="">Cash Out</a> page.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

                <div className='country-drop'>
                <h5>Features</h5> 
                </div>
                <div class="links">
                <Link to="#">Bet Boost</Link>
                <Link to="#">Multi Leg Same Game Parlay</Link>
                <Link to="#">Match Live</Link>
                <Link to="#">Live Alerts & Notifications</Link>
                <Link to="#">Live Streaming</Link>
                <Link to="#">Same Game Parlay</Link>
                <Link to="#">Quick Bet</Link>
                <Link to="#">Edit Bet</Link>
                </div>

                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
                </Container>
            </Tab>
            <Tab eventKey="second" title="Bet Placement" className="t-contain">
            <Container>
                

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="bdr-none">
                      <Accordion.Header className="accordion-head">Placing a bet</Accordion.Header>
                      <Accordion.Body>
                       <p>Log in to bet on a single selection:</p> 
                        <ol>
                        <li>Choose a sport from the sports navigation.</li>
                        <li>Select your market. </li>
                        <li>Click the price you wish to take which adds the selection to your bet slip.</li>
                        <li>Enter your wager.</li>
                        <li>If your selection is correct, select Place Bet and Confirm.</li>
                        </ol>
                        <p>After your bet is placed and confirmed, it can be viewed within My Bets, or your account <a href="#">History</a>.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    

                </Accordion>


                <div class="links top">
                <Link to="#">Sports Tax</Link>
                <Link to="#">Betting Terms</Link>
                <Link to="#">Each-Way (EW) Bets</Link>
                <Link to="#">How to place an Each Way bet</Link>
                <Link to="#">Placing a parlay</Link>
                <Link to="#">Same Game Parlay</Link>
                <Link to="#">Maximum Winnings</Link>
                <Link to="#">Each Way Extra</Link>
                <Link to="#">Odds Display</Link>
                </div>

                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
                </Container>
              
            </Tab>
            <Tab eventKey="third" title="Bet Settlement" className="t-contain">
             
            <Container>
                <div className='country-drop'>
                <h5>Settlement & Returns</h5> 
                </div>

                <Accordion defaultActiveKey="3">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="accordion-head">Soccer Stats Markets</Accordion.Header>
                      <Accordion.Body>
                       <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="accordion-head">Virtual Sports</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="accordion-head">Rule 4 Deductions</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3" className="bdr-none">
                      <Accordion.Header className="accordion-head">Bet settlement</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4" className="bdr-none">
                      <Accordion.Header className="accordion-head">Asian Handicap</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5" className="bdr-none">
                      <Accordion.Header className="accordion-head">Obvious Errors</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6" className="bdr-none">
                      <Accordion.Header className="accordion-head">Dead-Heat</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7" className="bdr-none">
                      <Accordion.Header className="accordion-head">My bet has been settled as no action</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8" className="bdr-none">
                      <Accordion.Header className="accordion-head">Settlement of Non-Runners</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9" className="bdr-none">
                      <Accordion.Header className="accordion-head">Viewing Results of Bets</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10" className="bdr-none">
                      <Accordion.Header className="accordion-head">Cancellation of Bets</Accordion.Header>
                      <Accordion.Body>
                      <p>We always settle bets as quickly as possible and as soon as the results are available.</p> 
                        <p>We aim to settle all bets within an hour of the event finishing and Live In Game bets during the event,
                        where the result is available. In some instances, there may be a slight delay whilst we wait for
                        confirmation of the results.</p>
                        <p>Once your bet has been settled, you can view a detailed breakdown of returns in your account <a href="#">History</a>.</p>
                        <p>Bets are settled in accordance with our <a href="#">Rules</a>, which determine bet settlement for individual markets.</p>
                        <p>You can view specific rules for bet settlement by visiting our dedicated Sports Rules page and selecting the sport of your choice.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
            </Container>
              
            </Tab>
            <Tab eventKey="fourth" title="Rules" className="t-contain">
              
            <Container className="alpha">

                  <Tabs defaultActiveKey="link-0" className="alpha-tab">
                    <Tab eventKey="link-0" title="A">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-1" title="B">
                    <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-2" title="C">
                    <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-3" title="D">
                    <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-4" title="E">
                    <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-5" title="F">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-6" title="G">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-7" title="H">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-8" title="I">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-9" title="J">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-10" title="K">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-11" title="L">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-12" title="M">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-13" title="N">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-14" title="O">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-15" title="P">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-16" title="Q">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-17" title="R">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-18" title="S">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-19" title="T">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-20" title="U">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-21" title="V">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                    <Tab eventKey="link-22" title="W">
                      <div className="c-rules">
                      <h6>Australian Rules</h6>
                      <h6>Auto Racing</h6>
                      </div>
                    </Tab>
                  </Tabs>

                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
            </Container>
                
            </Tab>
            <Tab eventKey="fifth" title="Promotions" className="t-contain">
              
            <Container>
                <div className='country-drop'>
                <h5>Offers</h5> 
                </div>

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="accordion-head">Sports Offers</Accordion.Header>
                      <Accordion.Body>
                       <p>Our great selection of Sports offers can be found by visiting our Promotions pages.</p> 
                        
                      </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="accordion-head">Claiming Offers</Accordion.Header>
                      <Accordion.Body>
                       <p>Cash Out, when available, gives you more control over your bets and offers you an opportunity to take a
                        return before an event has finished. When this feature is available you can Cash Out a bet in its entirety or
                        you can partially Cash Out your bet, whilst leaving the remainder of your wager to run.</p> 
                        <p>You can also create a request for your bet to automatically Cash Out <a href="">(Auto Cash Out)</a> where the Cash Out
                        amount offered reaches a chosen value.</p>
                        <p>For further details and full Terms and Conditions, refer to our <a href="">Cash Out</a> page.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
                <div className='country-drop'>
                <h5>Bonus Bets</h5> 
                </div>
                <div class="links">
                <Link to="#">Bonus Bets</Link>
                <Link to="#">Using a Bonus Bet</Link>
                <Link to="#">Using Bonus Bets</Link>
                </div>

                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
                </Container>
           
            </Tab>
            <Tab eventKey="sixth" title="College Sports" className="t-contain">
            <Container>
            

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="bdr-none">
                      <Accordion.Header className="accordion-head">College Sports</Accordion.Header>
                      <Accordion.Body>
                       <p>In order to comply with specific states betting market rules, there may be certain restrictions on what
                        College sports/markets are available to you.</p> 
                        <h6 className="clgSpt-para">Ohio</h6>
                        <p>No restrictions. Bets can be placed on any college sport within the state of Ohio.</p>
                        <h6 className="clgSpt-para">Colorado</h6>
                        <p>Bets can be placed on college sports within the state of Colorado, with the exception of player props,  which are prohibited.</p>
                        <h6 className="clgSpt-para">New Jersey</h6>
                        <p>Bets can be placed on any college sports games, with the exception of any college sports events that takes place in New Jersey or on any single event in which any New Jersey college team participate.</p>
                       
                      </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
                </Container>
            </Tab>
            <Tab eventKey="seventh" title="MLB 2023 Guide" className="t-contain">
            <Container>
            

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="bdr-none">
                      <Accordion.Header className="accordion-head">MLB 2023 Guide</Accordion.Header>
                      <Accordion.Body>
                       <p>The 2023 MLB Season is starting on March 30, with each team playing 162 games across two leagues. The
                        highest ranked teams will then enter the postseason playoff for the chance to enter the seven game World
                        Series, where the Houston Astros will be hoping to retain their World Series title after defeating the
                        Philadelphia Phillies 4-2 in 2022.</p> 
                        <p>If you require further information on Baseball betting topics and bet settlement not included in this guide,
                        see our <a href="#">Baseball Rules</a>.</p>
                        <p>You can use the below information to help guide you through Baseball betting markets and any queries
                        that you may have.</p>
                        <h6 className="clgSpt-para mlb">Questions:</h6>
                        <div className="mlb-links">
                        <Link>How does Baseball betting work?</Link>
                        <Link>Money Line</Link>
                        <Link>Run Line</Link>
                        <Link>Game Totals</Link>
                        <Link>MLB Futures</Link>
                        <Link className="sub-link">To Win Outright</Link>
                        <Link className="sub-link">To Win League</Link>
                        <Link className="sub-link">To Win Division</Link>
                        <Link>Key Queries on Baseball Market Bet Settlement</Link>
                        </div>

                        <h6 className="clgSpt-para mlb">How does Baseball betting work?</h6>
                        <p>You can find the markets we offer for the MLB on the Baseball <a href="#">classification</a> page.</p>
                        <h6 className="clgSpt-para mlb">Money Line</h6>
                        <p>Money Line is a bet on the team you think will win a game outright, with the tie not included. For Baseball,
                        all bets placed on the Money Line include extra innings, unless otherwise stated. This means that any runs
                        scored by either team in extra innings will count towards the settlement of your bet.</p>
                        <div className='bet365'>
                        <img src={placeBet} alt="Footer-image" className='customer-img' />
                        </div>
                        <h6 className="clgSpt-para mlb">Run Line</h6>
                        <p>Run Line is a market where a specified number of runs are added or deducted from a team's final score at
                        the end of the game. The Run Line applied to a team's score is for betting purposes only.</p>
                        <p>For example, if you placed a -1.5 Run Line bet on a team in the MLB, you would need to deduct 1.5 runs
                        from that team's score once the game is over. If the game finished at 4-2, the final result would be 2.5-2,
                        meaning your bet would win. However, if the game finished 3-2, the final result after a deduction of 1.5
                        runs would be 1.5-2, therefore this would result in a losing bet.</p>
                        <div className='bet365'>
                        <img src={placebet2} alt="Footer-image" className='customer-img' />
                        </div>

                        <h6 className="clgSpt-para mlb">Game Totals</h6>
                        <p>Game Totals involve predicting whether the total number of runs scored in a game by both teams will be
                         over or under a specified number.</p>
                        <p>For example, you can place a bet on the Houston Astros and St. Louis Cardinals combined scoring Over
                        8.5 runs by the end of the game. If the game finished 5-4, then the total number of runs combined would
                        equal 9, meaning your bet would win.</p>
                        <div className='bet365'>
                        <img src={placebet3} alt="Footer-image" className='customer-img' />
                        </div>

                        <h6 className="clgSpt-para mlb">MLB Futures</h6>
                        <div className="win">To Win Outright</div>
                        <p>You can choose which Team you think will win the World Series outright. Each Way betting is available for
                        this market by ticking the E/W box in your Bet Slip.</p>
                        <div className='bet365'>
                        <img src={placebet4} alt="Footer-image" className='customer-img' />
                        </div>

                        <div className="win">To Win League</div>
                        <p>You can choose which Team you think will win the League outright. Each Way betting is available for this
                          market by ticking the E/W box in your Bet Slip.</p>
                        <div className='bet365'>
                        <img src={placebet5} alt="Footer-image" className='customer-img' />
                        </div>

                        <div className="win">To Win To Win Division</div>
                        <p>You can choose which Team you think will win the Division outright.</p>
                        <div className='bet365'>
                        <img src={placebet6} alt="Footer-image" className='customer-img' />
                        </div>

                        <h6 className="clgSpt-para mlb">Key Queries for Baseball Bet Settlement</h6>
                        <p>For all bet settlement queries please see our Baseball Rules.</p>

                        <h6 className="clgSpt-para mlb">Related Articles:</h6>
                        <Link class="last-link">Sports FAQs<br></br>Baseball Rules</Link>
                        <br></br>
                        <Link class="back-top">Back To The Top ↑</Link>
                      </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Button className="bot-btn">Can't find an answer? Contact Us</Button>
                </Container>
            </Tab>
          </Tabs>                                             
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SportsRule;
