import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import UFCMatchBody from './UFCMatchBody'
import './UFCMatch.css'
import HeaderMain from '../HeaderMain/HeaderMain'
import FooterNew from '../FooterNew/FooterNew'
import RightSidebarBet from './RightSidebarBet'
import BoxingListLeftSide from '../BoxingList/BoxingListLeftSide'

const UFCMatch = () => {
  return (
    <div className='boxing-match-page'>

        <HeaderMain/>

        <Container fluid>
        <Row>

        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
          <BoxingListLeftSide/>
        </Col>

        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <UFCMatchBody/>
            <FooterNew/>
        </Col>
        

        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
            <RightSidebarBet/>
        </Col> 
        
        </Row>
        </Container>
      
    </div>
  )
}

export default UFCMatch
