import React, {useState} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import slider2small from '../../images/slider-2-small.png'
import slider1small from '../../images/slider-1-small.png'

const SmallSliderRight = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  
    return (
      <div className='carousel-part-mid-home'>
      <div className='head-caroser'></div>
      <Carousel activeIndex={index} onSelect={handleSelect}>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider2small}
            alt="First slide"
          />
          <Carousel.Caption>
          <h3>Beloved-1</h3>
        </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider1small}
            alt="Second slide"
          />
          <Carousel.Caption>
          <h3>Beloved-2</h3>
        </Carousel.Caption>
        </Carousel.Item>
        
      </Carousel>
      </div>
    );

}

export default SmallSliderRight
