import React from 'react'
import HeaderHelp from '../HeaderHelp/Header'
import Container from 'react-bootstrap/esm/Container'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Account = () => {
  return (
    <div className='myaccount-page'>
      <HeaderHelp />
      <section className="help-section">
        <div className="top-head">
          <p className="terms-rules sports">Login & Registration</p>
        </div>
        <div className="tab-width">



          <Container className='myaccount-page-aacordion'>


            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion-head account-title">Account Username / Password</Accordion.Header>
                <Accordion.Body>
                  <p className='terms-rules acc-para'>Please be aware that your password is case sensitive and that your account will become locked after three consecutive failed login attempts.</p>
                  <p className='terms-rules acc-para'>For a reminder of your username, or to reset a forgotten password, follow the below steps.</p>
                  <p className='terms-rules acc-para'>To retrieve your username first press "Having trouble logging in?". Then, select "Forgotten your username?", enter your details and select "Get Username" to receive an email reminder.
                  </p>
                  <p className='terms-rules acc-para'>To reset your password, enter your Username in the "Having trouble logging in?" form and select "Next". Then enter your details in the "Send Me A Reset Code" form and select "Get a Reset Code" to receive a unique six-digit code by email or SMS.</p>
                  <p className='terms-rules acc-para'>If your account becomes locked or you receive the error code LL01, please Contact Us for further assistance.</p>



                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordion-head">Keep Me Logged In</Accordion.Header>
                <Accordion.Body>
                  <p className='terms-rules acc-para'>Keep me logged in allows you to access your account without having to enter your login details each time you open Mmadness on your mobile or tablet.</p>
                  <p className='terms-rules acc-para'>To enable this feature, select Keep me logged in on the Log In pop-up and then enter your username and password.</p>
                  <p className='terms-rules acc-para'>To disable this feature, select Log Out and the next time you access your account you will be prompted to enter your username and password.
                  </p>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header className="accordion-head">Passcode Login</Accordion.Header>
                <Accordion.Body>
                  <p className='terms-rules acc-para'>Passcode Login allows you to log in to your Mmadness account on mobile by entering a four digit passcode, as an alternative to your username and password. You will be prompted to create a four-digit passcode when you log in normally. If you wish to use Passcode Login, select Create Passcode and enter a memorable four-digit number.</p>
                  <p className='terms-rules acc-para'>Once your Passcode Login is set up, you will be able to login using your four-digit passcode on mobile.</p>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header className="accordion-head">Alternative Login Methods</Accordion.Header>
                <Accordion.Body>
                  <p className='terms-rules acc-para'>Touch ID, Face ID and Fingerprint Login allows you to log into the Mmadness app quickly and securely. If your phone has Touch ID, Face ID or Fingerprint Login capability, the feature must be enabled within your device settings in order for it to be used to log in.</p>
                  <p className='terms-rules acc-para'>Once enabled, log into the Mmadness app and you will be presented with a pop-up. Simply follow the instructions to enable the chosen feature for future logins.</p>
                  <p className='terms-rules acc-para'>You can turn the features off via My Account in the Account Menu .</p>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>




            <Button className="bot-btn">Can't find an answer? Contact Us</Button>
          </Container>







        </div>
      </section>
    </div>
  )
}

export default Account
