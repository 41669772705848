import React, { useRef, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Container from "react-bootstrap/esm/Container";
import HeaderHelp from '../HeaderHelp/Header'
import { Link } from 'react-router-dom';
import bankimg from '../../img/visa-img.png'
import bankupi from '../../img/upi-img.png'
import bankdeb from '../../img/visadeb-img.png'
import bankinb from '../../img/astroplay-img.png'
import bankskr from '../../img/skrill-img.png'
import banknet from '../../img/neteller-img.png'
import countryicon from '../../image/country-icon.svg'
import currencyicon from '../../image/currency-icon.svg'
import Axios from '../../Axios';


import { BsChevronCompactDown } from 'react-icons/bs'
import Table from 'react-bootstrap/Table';
import FooterHelp from '../FooterHelp/FooterHelp'
import './Deposit.css'
const Deposit = () => {
  const Countries = [
    { label: "Albania", value: 355 },
    { label: "Argentina", value: 54 },
    { label: "Austria", value: 43 },
    { label: "Cocos Islands", value: 61 },
    { label: "Kuwait", value: 965 },
    { label: "Sweden", value: 46 },
    { label: "Venezuela", value: 58 }
  ];

  const Currency = [
    { label: "USD", value: 355 },
    { label: "EUR", value: 54 },
    { label: "GBP", value: 43 },
    { label: "ARS", value: 61 },
    { label: "BGN", value: 965 },
    { label: "DKK", value: 46 },
    { label: "HUF", value: 58 }
  ];

  const amount = useRef(null)
  const [err, seterr] = useState("")

  const onSubmit = async () => {
    console.log(amount.current.value, "logs")
    if (amount.current.value === "") {
      seterr("Please Enter Amount")
    } else {
      const { data } = await Axios.post(`/auth/makePayment`, {
        amount: amount.current.value
      }, {
        headers: {
          "Authorization": window.localStorage.getItem('Mellifluous')
        }
      })
      window.location.replace(data?.result?.url);
    }

  }
  return (



    <div className='deposit-page'>
      <HeaderHelp />
      <section className="help-section">
        <div className="top-head">
          <p className="terms-rules sports">Payment Methods</p>
        </div>

        <div className='tab-sctn'>

          <div className='head-tab'>
            <Tabs defaultActiveKey="first">
              <Tab eventKey="first" title="Deposit">
                <div className='drop-row'>
                  <Row>

                    {/* <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                      <div className='country-drop'>
                        <div className='country'>
                          <img src={countryicon} alt="countryicon" className='customer-img' />
                          <h5>Country</h5>
                        </div>

                        <Select options={Countries} />
                      </div>

                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                      <div className='country-drop'>
                        <div className='country'>
                          <img src={currencyicon} alt="currency-icon" className='customer-img' />
                          <h5>Currency</h5>
                        </div>

                        <Select options={Currency} />

                      </div>
                    </Col>
                    <div className='full-accordion'>
                      <Table className='payment-table'>
                        <thead>
                          <tr>
                            <th>Payment Method</th>
                            <th>Fee</th>
                            <th>Process Time</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                      </Table>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header><img src={bankinb} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>AstroPay is a fast and secure method of funding your Mmadness account via a virtual prepaid voucher or eWallet using multiple payment methods including bank transfers, cash payments, Credit and Debit cards, plus many more.
                            </p>
                            <p className='terms-rules acc-para'>Mmadness and AstroPay apply no charges for using this method.</p>
                            <p className='terms-rules acc-para'>To transfer funds, click on Deposit and select Astropay from the list of available payment methods. Enter your deposit amount and select Deposit. You will then be transferred to the Astropay website. Here you will have the option to deposit from your Astropay eWallet or an Astropay Voucher.</p>

                            <p className='terms-rules acc-para'>To deposit from your AstroPay eWallet, enter your AstroPay phone number and click Continue. A 6-digit activation code will then be sent to your phone number. Enter this to proceed. Once logged in, you will be given the option to deposit from your available balance or via an available payment method. Once complete, the amount will be credited to your Mmadness account upon receipt.</p>
                            <p className='terms-rules acc-para'>Alternatively, enter your Astropay Voucher details and click 'Confirm Deposit'. The amount will then be instantly credited to your Mmadness account.</p>
                            <p className='terms-rules acc-para'>The name registered on your AstroPay Account must match the name registered on your Mmadness account.
                            </p>
                            <p className='terms-rules acc-para'>To open an AstroPay account or for further information, please visit <Link to="">www.astropay.com</Link> .</p>
                            <p className='terms-rules acc-para'><b>Withdrawing</b></p>
                            <p className='terms-rules acc-para'>If you have deposited by AstroPay, all withdrawals will be processed by bank transfer. Please refer to the Wire Transfer section for further information.</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header> <img src={bankimg} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>You can make secure online payments instantly using Visa and Mastercard issued Credit Cards. We also accept prepaid cards issued by Visa and Mastercard, you may need to confirm with the issuer whether there are any restrictions on the card.</p>
                            <p className='terms-rules acc-para'>Deposits are instant and Mmadness apply no charges for using this method.</p>
                            <p className='terms-rules acc-para'>To transfer funds, click on Deposit and select Credit/Debit Card from the list of available payment methods. Enter your card details, deposit amount and select Deposit. Once complete, the amount will be instantly credited to your Mmadness account.
                            </p>
                            <p className='terms-rules acc-para'>The card holders name must match the name registered on your Mmadness account.</p>
                            <p className='terms-rules acc-para'>Card deposits will appear on your bank statement as being paid to Mmadness.</p>
                            <p className='terms-rules acc-para'>Some deposits may be processed via Mastercard ID Check or Visa Secure, which are an extra level of protection used when making payments from your card. This may require you to verify ownership of your card by providing security information such as your password, fingerprint authentication, facial recognition or a one-time code to approve the payment. This service is quick, easy and free to set up.</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header><img src={bankdeb} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>You can make secure online payments instantly using Visa, Electron, Maestro and Mastercard Debit Cards. Deposits are instant and Mmadness apply no charges for using this method.</p>
                            <p className='terms-rules acc-para'>To transfer funds, click on Deposit and select Credit/Debit Card from the list of available payment methods. Enter your card details, deposit amount and select Deposit. Once complete, the amount will be instantly credited to your Mmadness account.</p>
                            <p className='terms-rules acc-para'>The card holders name must match the name registered on your Mmadness account.</p>
                            <p className='terms-rules acc-para'>Card deposits will appear on your bank statement as being paid to Mmadness.</p>
                            <p className='terms-rules acc-para'>Some deposits may be processed via Mastercard ID Check or Visa Secure, which are an extra level of protection used when making payments from your card. This may require you to verify ownership of your card by providing security information such as your password, fingerprint authentication, facial recognition or a one-time code to approve the payment. This service is quick, easy and free to set up.</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header> <img src={bankupi} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>UPI is a fast and secure method of funding your Mmadness account via an instant bank transfer using your BHIM UPI app.</p>
                            <p className='terms-rules acc-para'>To deposit with UPI, you will need to download the BHIM UPI app on your mobile device.</p>
                            <p className='terms-rules acc-para'>The Document ID registered to your Mmadness account will pre-populate on the deposit page. If you do not have a Document ID registered to your Mmadness account, enter either your Aadhaar, PAN card or Passport Number in order to complete a deposit. This will be stored for subsequent deposits.</p>
                            <p className='terms-rules acc-para'>When making your first deposit, you will be required to complete additional third party verification. Enter your Aadhaar ID as your Document ID to quickly complete payment verification. Alternatively, enter your PAN card or Passport Number.</p>
                            <p className='terms-rules acc-para'>After selecting Deposit, details on how to complete payment verification will be displayed.</p>
                            <p className='terms-rules acc-para'>Once you have successfully completed verification, you will need to return to the deposit page to complete your payment or initiate a new deposit.</p>
                            <p className='terms-rules acc-para'>To transfer funds, click on Deposit and select UPI from the list of available payment methods. Enter your deposit amount and select Deposit. You will then be directed to the UPI website where you will need to enter your UPI ID and select Verify and Pay. You will need to open your BHIM UPI App to confirm the payment. Once confirmed, you will need to return to Mmadness and the amount will be instantly credited to your Mmadness account.</p>
                            <p className='terms-rules acc-para'>The name registered on your BHIM UPI account must match the name registered on your Mmadness account.</p>
                            <p className='terms-rules acc-para'>To open a BHIM UPI account or for further information, please visit <Link to="">www.bhimupi.org.in.</Link></p>
                            <p className='terms-rules acc-para'><b>Withdrawal</b></p>
                            <p className='terms-rules acc-para'>If you have deposited by UPI, all withdrawals will be processed by bank transfer. Please refer to the Wire Transfer section for further information.</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    <Col xl={2} lg={2} md={12} sm={12} xs={12} >
                      <div className='payment_div' >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <input type='number' ref={amount} onChange={() => { seterr("") }} />
                          <p style={{ color: "red", textAlign: "center" }} >{err}</p>
                        </div>

                        <button onClick={onSubmit} className='payment_btn' >Deposite</button>
                      </div>
                    </Col>
                  </Row>
                </div>

              </Tab>

              <Tab eventKey="second" title="Withdrawals">
                <div className='drop-row'>
                  <Row>

                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                      <div className='country-drop'>
                        <h5>Country</h5>
                        <Select options={Countries} />
                      </div>

                    </Col>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12} >
                      <div className='country-drop'>
                        <h5>Currency</h5>
                        <Select options={Currency} />

                      </div>
                    </Col>
                    <div className='full-accordion'>
                      <Table className='payment-table'>
                        <thead>
                          <tr>
                            <th>Payment Method</th>
                            <th>Fee</th>
                            <th>Process Time</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                      </Table>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header><img src={bankskr} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>If you have deposited by Skrill, all withdrawals will be processed to the same Skrill account.
                            </p>
                            <p className='terms-rules acc-para'>Mmadness will not apply any charges for withdrawals made by Skrill. The funds will be credited to you within 24 hours of your withdrawal request.</p>
                            <p className='terms-rules acc-para'>Once funds have been withdrawn to your Skrill account, funds can be cashed out via Bank Wire or in some countries paid onto a Skrill Prepaid Mastercard. The Skrill Prepaid Mastercard allows you to spend your funds at multiple retailers or to take out cash via an ATM. Please visit www.skrill.com for further details.</p>

                            <p className='terms-rules acc-para'>You can request a withdrawal via the Account Menu  ; select Bank then Withdraw.</p>

                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header> <img src={bankimg} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>If you have deposited by Credit Card, all withdrawals will be processed to the same card where possible. Mmadness apply no charges for using this method.</p>
                            <p className='terms-rules acc-para'>Funds may take around 1-5 working days to reach your bank account.</p>
                            <p className='terms-rules acc-para'>Card withdrawals will appear on your bank statement as Mmadness.
                            </p>
                            <p className='terms-rules acc-para'>Where we are unable to withdraw back to your Debit Card, funds will be returned to you via Wire Transfer to the name registered on your Mmadness account.</p>
                            <p className='terms-rules acc-para'>Where a bank transfer withdrawal is necessary, you will be prompted to submit your bank details when clicking Withdraw. Please refer to the Wire Transfer section for further information</p>

                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header><img src={bankdeb} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>If you have deposited by Debit Card, all withdrawals will be processed to the same card where possible. Mmadness apply no charges for using this method.</p>
                            <p className='terms-rules acc-para'>Funds may take around 1-5 working days to reach your bank account.</p>
                            <p className='terms-rules acc-para'>Card withdrawals will appear on your bank statement as Mmadness.</p>
                            <p className='terms-rules acc-para'>Where we are unable to withdraw back to your Debit Card, funds will be returned to you via Wire Transfer to the name registered on your Mmadness account.</p>
                            <p className='terms-rules acc-para'>Where a bank transfer withdrawal is necessary, you will be prompted to submit your bank details when clicking Withdraw. Please refer to the Wire Transfer section for further information</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <div className='accord-head'>
                            <Accordion.Header> <img src={banknet} alt="Footer-image" className='customer-img' /></Accordion.Header>
                            <Accordion.Header>Free</Accordion.Header>
                            <Accordion.Header>1-5 Banking Days</Accordion.Header>
                            <Accordion.Header>$10.00</Accordion.Header>
                            <Accordion.Header>$10,000.00</Accordion.Header>
                            <Accordion.Header>Bank Transfer</Accordion.Header>
                            <Accordion.Header> <BsChevronCompactDown /> </Accordion.Header>
                          </div>

                          <Accordion.Body>
                            <p className='terms-rules acc-para'>If you have deposited by Neteller, all withdrawals will be processed to the same Neteller account.</p>
                            <p className='terms-rules acc-para'>Mmadness will not apply any charges for withdrawals made by Neteller.</p>
                            <p className='terms-rules acc-para'>Once funds have been withdrawn to your Neteller account, funds can be cashed out via Bank Wire, Cheque or paid in some countries onto a Neteller Prepaid Mastercard. The Neteller Prepaid Mastercard allows you to spend your funds at multiple retailers or to take out cash via an ATM. Please visit <Link to="">www.neteller.com</Link> for further details.</p>
                            <p className='terms-rules acc-para'>You can request a withdrawal via the Account Menu  ; select Bank then Withdraw.</p>

                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <Col xl={2} lg={2} md={12} sm={12} xs={12} ></Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>


        </div>
        <FooterHelp />

      </section>

    </div>
  )
}

export default Deposit
