import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'
import customfoot1 from '../../images/custom-foot-1.png'
import customfoot2 from '../../images/custom-foot-2.png'
import customfoot3 from '../../images/custom-foot-3.png'
import logomainpage from '../../images/logo-main.png'

import rg1 from '../../images/rg-1.png'
import rg2 from '../../images/rg-2.png'
import rg3 from '../../images/rg-3.png'


import pm1 from '../../images/pm-1.png'
import pm2 from '../../images/pm-2.png'
import pm3 from '../../images/pm-3.png'
import pm4 from '../../images/pm-4.png'
import pm5 from '../../images/pm-5.png'
import pm6 from '../../images/pm-6.png'
import pm7 from '../../images/pm-7.png'
import pm8 from '../../images/pm-8.png'
import pm9 from '../../images/pm-9.png'

import si1 from '../../images/si-1.png'
import si2 from '../../images/si-2.png'
import si3 from '../../images/si-3.png'
import si4 from '../../images/si-4.png'
import si5 from '../../images/si-5.png'

import gl1 from '../../images/gl-1.png'



const FooterMain = () => {
  return (
                <>

                <div className='footer-1'>
                <Container>
                <Row>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='flex-custom-block-foot'>
                        <div className='cusomer-service'>
                        <div className='left-custom'><img src={customfoot1} alt="24-7-Customer support"/></div>
                        <div className='rightt-custom'><span>Customer support</span><p>24/7 at your service</p></div>
                        </div>
                        <div className='cusomer-service'>
                        <div className='left-custom'><img src={customfoot2} alt="Guide-for-new-members"/></div>
                        <div className='rightt-custom'><span>Guide for new members</span><p>Frequently asked questions<br/> and
helpful guides</p><Link>Explore now</Link></div>
                        </div>
                        <div className='cusomer-service'>
                        <div className='left-custom android'><img src={customfoot3} alt="android"/></div>
                        </div>
                    </div>
                </Col>

                </Row>
                </Container>
                </div>



                <div className='footer-2'>
                <Container>
                <Row>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                   <div className='flex-custom-block-foot-new'>
                    <div className='Responsible-Gambling-payment-method common-head'>
                        <label>Responsible Gambling</label>
                        <div className='ResponsibleGambling-games-payment'>
                            <div><img src={rg1} alt="Responsible-Gambling"/></div>
                            <div><img src={rg2} alt="Responsible-Gambling"/></div>
                            <div><img src={rg3} alt="Responsible-Gambling"/></div>
                        </div>
                    </div>
                    <div className='Responsible-Gambling-payment-method common-head'>
                        <label>Payment-method</label>
                        <div className='ResponsibleGambling-games-payment'>
                            <div><img src={pm1} alt="payment-method"/></div>
                            <div><img src={pm2} alt="payment-method"/></div>
                            <div><img src={pm3} alt="payment-method"/></div>
                            <div><img src={pm4} alt="payment-method"/></div>
                            <div><img src={pm5} alt="payment-method"/></div>
                            <div><img src={pm6} alt="payment-method"/></div>
                            <div><img src={pm7} alt="payment-method"/></div>
                            <div><img src={pm8} alt="payment-method"/></div>
                            <div><img src={pm9} alt="payment-method"/></div>
                        </div>
                    </div>
                   </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                   <div className='flex-custom-block-foot-new community-website-gaming-lecense'>
                    <div className='Responsible-Gambling-payment-method common-head left'>
                        <label>Community Website</label>
                        <div className='ResponsibleGambling-games-payment social-icons-foot-madness'>
                            <div><img src={si1} alt="Community-website"/></div>
                            <div><img src={si2} alt="Community-website"/></div>
                            <div><img src={si3} alt="Community-website"/></div>
                            <div><img src={si4} alt="Community-website"/></div>
                            <div><img src={si5} alt="Community-website"/></div>
                        </div>
                    </div>
                    <div className='Responsible-Gambling-payment-method common-head right'>
                    <label>Gaming license</label>
                    <div className='ResponsibleGambling-games-payment social-icons-foot-madness'>
                        <div><img src={gl1} alt="Gaming-license"/></div>
                    </div>
                    </div>
                   </div>
                </Col>

                </Row>
                </Container>
                </div>



                <div className='footer-3'>
                <Container>
                <Row>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='Top-betting-exchange-site'>
                 <label>Top betting exchange site in Bangladesh, India and Southeast Asia</label>
                 <p>A betting exchange is essentially an online tool that allows gamblers to bet directly against each other without a bookmaker market. There are two types of betting in cricket matches. First is to bet on the complete result of live
cricket matches. The other is based on a team's direct cricket score made over the first 6 overs. Online betting is developing as an emerging industry in South East Asia especially in Bangladesh and India, where bettors can
choose from one of the exciting top betting exchange sites in Bangladesh and South East Asia region. If you are looking for an attractive, reliable and safe cricket betting site in Bangladesh then you can join us. bet Live is a
popular online betting site across Asia. We not only do cricket predictions but also prefer other interesting online gaming products. One of our ongoing promotional offers is "Refer a friend and win Rs. 100 bonus" for all our
members. This bonus amount can be used in all types of games. Transfer Processing |</p>
                </div>
                </Col>


                </Row>
                </Container>
                </div>


                <div className='footer-4'>
                <Container>
                <Row>

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='logomainpage foot-logo'><img src={logomainpage} alt="logo-main-page"/></div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='foot-last-right-memu-outer'>
                <div className='foot-last-right-memu'>
                <nav>
                
                <ul className='menu-part-list'>
                <li><Link>Affiliate</Link></li>
                <li><Link>Responsible Gambling</Link></li>
                <li><Link>About us</Link></li>
                </ul>
                </nav>
                </div>
                <p>© 2023 Fantasy mmadness Copyright. All rights reserved</p>
                </div>
                </Col>

                </Row>
                </Container>
                </div>


                </>
  )
}

export default FooterMain
